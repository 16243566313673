// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './MainNav.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';


// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { PoweredBy, SelectedIndicator, StoreNext } from 'ui/basic';
import { Logo } from '../Logo';


// UTILS
import { useTranslate } from 'utils/translator';
import { pxToNumber } from 'utils/styleTools';
import { handleRipple } from 'utils/ripple';
import { useBreakpoint } from 'utils/hooks';

// ROUTING
import { alsoHighlightFor } from 'features/framework/utils/routeNext';

// STORE
import { useSelector } from 'react-redux';
import { selectIsCustomLogoActive } from 'store/selectors/configuration';
import UpgradeTrial from './UpgradeTrial/UpgradeTrial';

// CONFIG & DATA
// const Config = {};
const { MenuClose } = IconsSvg;


// COMPONENT: MainNav
const MainNav = (props) => {
  // PROPS
  const {
    mainNavRoutes,
    onClose,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const location = useLocation();

  // INDICATOR: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ activeIndex, setActiveIndex ] = useState(null);

  // CUSTOM LOGO: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);

  // RESPONSIVE
  const { isXs, isL, isXl } = useBreakpoint();

  // ITEM CLICK
  const handleItemClick = (event) => {
    // ripple
    handleRipple(event, styles.colorPrimary3);

    // close menu on click for smaller viewports
    if (!isL && !isXl) {
      setTimeout(() => {
        onClose?.();
      }, styles.animationDurationShortMs);
    }
  };


  // RENDER: MainNav
  return (
    <div
      className={classNames(styles.mainNav)}
      data-test='MainNav'
    >
      { /* HEADER: ONLY LARGE SCREENS */ }
      { !isXs && (
        <header className={styles.header}>

          { /* logo link */ }
          <div className={classNames(styles.logo)}>
            <Logo link='/' />
          </div>

          { /* close icon */ }
          <div
            role='presentation'
            className={styles.menuCloseIcon}
            onClick={onClose}
          >
            <MenuClose />
          </div>

        </header>
      ) }

      { /* CONTENT */ }
      <section className={styles.content}>
        { /* INDICATOR */ }
        { activeIndex !== null && (
          // render only when first index is set
          // so first appearance of indicator is immediate and not transitioned
          <SelectedIndicator position={activeIndex * pxToNumber(styles.menuItemHeight)} />
        ) }

        { /* MAIN NAV ITEMS / ROUTES */ }
        { mainNavRoutes.map((route, index) => {
          const { inMainNav, pageType } = route;
          const { label, labelKey, linkType } = inMainNav;

          const path = inMainNav.mainNavPath || route.path;
          const isExternal = pageType === 'external';

          // highlight
          const highlight = path === '/'
            ? location.pathname === path
            : location.pathname.startsWith(path)
              || alsoHighlightFor(location, route);

          // setActiveIndex
          if (highlight) {
            if (activeIndex !== index && (!route.pageType || route.pageType === 'nested')) {
              setActiveIndex(index);
            }
          }

          // LINK
          return (
            <Link
              key={path}
              className={classNames(styles.menuItem, {
                [styles.highlight]: highlight,
                [styles.small]: linkType === 'small',
              })}
              onClick={handleItemClick}
              target={isExternal ? '_blank' : undefined}
              to={isExternal ? { pathname: path } : path}
            >
              { /* span necessary for ripple, so we can apply a z-index */ }
              <span>{ translate(labelKey) || label }</span>
            </Link>
          );
        }) }
      </section>

      { /* UPGRADE TRIAL */ }
      <StoreNext>
        <UpgradeTrial />
      </StoreNext>

      <footer>
        { /* FOOTER FOR CUSTOM LOGO */ }
        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        ) }
      </footer>
    </div>
  );
};

export default MainNav;
