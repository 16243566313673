import React, { useEffect, useState } from 'react';

import styles from 'styles-imports/shared.scss';

// transitions
import fadeTransitions from './fade.module.scss';

// 3RD PARTY
import { CSSTransition } from 'react-transition-group';

/**
 * BluCSSTransition
 * extends the functionality of react-transition-group's CSSTransition
 *  - it wires the global animationDurationMs from _animations.scss to the transition
 *  - it applies mountOnEnter, unMountOnExit per default
 * @param {*} props
 */

const BluCSSTransition = (props) => {
  const {
    children,
    classNames,
    timeout,
    in: transitionIn,
    type = 'fade',
    nodeRef,
  } = props;

  const [ classNamesInternal, setClassNamesInternal ] = useState();
  const [ internalTransitionIn, setInternalTransitionIn ] = useState(false);

  useEffect(() => {
    // if transitionIn=true, in order for the animation to execute, the 'in' value on mount must be false.
    setInternalTransitionIn(Boolean(transitionIn));
  }, [ transitionIn ]);

  useEffect(() => {
    let newClassNamesInternal;
    switch (type) {
      case 'fade': {
        newClassNamesInternal = { ...fadeTransitions };
        break;
      }
      default:
    }

    setClassNamesInternal(newClassNamesInternal);
  }, [ type ]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={internalTransitionIn}
      classNames={classNames || classNamesInternal}
      timeout={Number(timeout ?? styles.animationDurationMs)}
      mountOnEnter
      unmountOnExit
    >
      { children }
    </CSSTransition>
  );
};

export default BluCSSTransition;
