// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './ReportPreview.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, DropDownSearchable, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { CustomReport } from 'ui/molecules/CustomReport';
import { apiNext } from 'apiNext';

// STORE
import { useSelector, useDispatch } from 'react-redux';

import { getAssessmentReport } from 'store/actions';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { replace360PeerResults } from 'utils/assessment';
import { getFullName } from 'utils/users';


// COMPONENT: ReportPreview
const ReportPreview = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ reportIdLoading, setReportIdLoading ] = useState(false);
  const [ reportId, setReportId ] = useState('');
  const [ reportError, setReportError ] = useState('');
  const [ showReport, setShowReport ] = useState(false);
  const [ reportStructure, setReportStructure ] = useState();
  const [ assessmentId, setAssessmentId ] = useState('');

  const username = getFullName(me);
  const assessment = JSON.parse(JSON.stringify(assessmentsFromStore?.find(({ id }) => id === assessmentId) ?? {}));
  const { results: assessmentResults, resultsRequestError } = assessment;
  assessment.reportStructure = reportStructure;
  assessment.peerAssessment = assessmentResults?.some(({ peerResults }) => peerResults);

  // Peer360 results preprocessing
  const assessmentResultsInternal = replace360PeerResults(assessment, assessmentResults);
  const hasResults = Boolean(assessmentResultsInternal.length);

  const getReportStructure = async (id) => {
    setReportStructure();
    setReportError();
    setReportIdLoading(true);
    if (!id) {
      return;
    }
    try {
      // TODO: use correct endpoint
      const res = await apiNext.get(`/core/assessments/${id}/report`);
      setReportStructure(res.report);
    } catch (e) {
      setReportError(e.responseContent?.error?.errorMessage);
    } finally {
      setReportIdLoading(false);
    }
  };
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: ReportPreview
  return (
    <div className={styles.reportPreview}>
      <div className='bluTypeXxs'>
        { translate('report_preview_title') }
      </div>
      <div className='bluTypeCopy marginTopL'>
        { translate('report_preview_description', [ '{{username}}', username ]) }
      </div>

      <div className={classNames(styles.inputSection, 'marginTopS')}>
        <InputNext
          label={translate('report_preview_report_field')}
          onChange={setReportId}
          clearValueOnConfirm={false}
          onConfirm={() => getReportStructure(reportId)}
          errorMessage={reportId && reportError}
          hint={reportStructure && translate('report_preview_report_field_success')}
        />
        <div className={styles.reportLoadBtn}>
          <Button
            disabled={!reportId || reportIdLoading}
            onClick={() => getReportStructure(reportId)}
          >
            { translate('get_lbl') }
          </Button>
        </div>
      </div>

      <div className={styles.inputSection}>
        <DropDownSearchable
          placeholder={translate('report_preview_assessment_field')}
          activeValue={assessmentId}
          errorMessage={(resultsRequestError || assessmentResults?.length === 0) && translate('task_list_no_results')}
          hint={hasResults && translate('report_preview_result_field_success')}
          onChange={(activeOption) => {
            const id = activeOption?.value;
            setAssessmentId(id);
            if (id) {
              dispatch(getAssessmentReport({ id }));
            }
          }}
          options={assessmentsFromStore
          .filter(({ id }) => !Object.values(ASSESSMENT_TYPES).includes(id))
          .map(({ id, title }) => ({ value: id, label: title }))}
        />
      </div>

      <div className={styles.viewSection}>
        <Button
          disabled={!(reportStructure && hasResults)}
          onClick={() => setShowReport(true)}
        >
          { translate('assessment_show_report') }
        </Button>
      </div>

      { /* FLOATERS */ }
      { showReport && (
        <CustomReport
          assessment={assessment}
          assessmentResults={assessmentResultsInternal}
          assessmentResultsAll={assessmentResultsInternal}
          onClose={() => setShowReport(false)}
        />
      ) }
    </div>
  );
};

export default ReportPreview;
