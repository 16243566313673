// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import styles from './SliderBipolar.module.scss';

// ASSETS
import { ReactComponent as ArrowLeft } from 'assets/icons/icn_arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/icn_arrow_right.svg';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useAutoReset } from 'utils/hooks';

/**
 * SliderBipolar:
 *  - layout
 *    - automatically takes full width
 *    - works responsively (layout and value ranges)
 *  - props
 *    - `from` and `to` should be equally distant from 0 for proper presentation
 *    - works well with any `step` value
 *  - interaction
 *    - knob drag n drop
 *    - click anywhere to choose that value
 *    - double click to reset (value is set to null)
 */

// COMPONENT: SliderBipolar
const SliderBipolar = (props) => {
  // PROPS
  const {
    value = null,
    from = -5,
    to = 5,
    step = 1,
    onChange,
  } = props;

  const [ knobValue, setKnobValue ] = useState(value);
  const [ transitioning, setTransitioning ] = useAutoReset(false, Number(styles.animationDurationMs));
  const node = useRef();

  // Propagate new value after rounding it to nearest step
  useEffect(() => {
    let roundedValue = null;
    if (Number.isFinite(knobValue)) {
      roundedValue = Math.round(Math.abs(knobValue) / step) * step;
      if (roundedValue && knobValue < 0) {
        roundedValue *= -1;
      }
    }
    onChange?.(roundedValue);
  }, [ onChange, step, knobValue ]);

  const handleKey = useCallback((event) => {
    const { key } = event;
    switch (key) {
      case 'ArrowRight': {
        const knobValueInternal = Math.max(from, Math.min(to, (knobValue ?? 0) + 1));
        setKnobValue(knobValueInternal);
        onChange?.(knobValueInternal);
        break;
      }
      case 'ArrowLeft': {
        const knobValueInternal = Math.max(from, Math.min(to, (knobValue ?? 0) - 1));
        setKnobValue(knobValueInternal);
        onChange?.(knobValueInternal);
        break;
      }
      default:
    }
  }, [ knobValue, from, to, onChange ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [ handleKey ]);

  // EVENT HANDLES
  const handleMove = (event) => {
    const { x: sliderX, width: sliderWidth } = node.current.getBoundingClientRect();
    const eventX = event.pageX ?? event.touches[0].pageX;
    const newKnobX = Math.min(Math.max(eventX - sliderX, 0), sliderWidth);
    let newKnobValue = ((newKnobX / sliderWidth) * (to - from)) + from;
    newKnobValue = Math.round(newKnobValue * 100) / 100;
    setKnobValue(newKnobValue);
  };

  const handleDragEnd = () => {
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('touchmove', handleMove);
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchend', handleDragEnd);
  };

  const handleDragStart = () => {
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('touchmove', handleMove);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchend', handleDragEnd);
  };

  const handleClick = (event) => {
    setTransitioning(true);
    handleMove(event);
  };

  const handleDoubleClick = () => {
    setTransitioning(true);
    setKnobValue(null);
  };

  const handleDoubleClickStop = (event) => {
    event.stopPropagation();
  };


  // RENDER: SliderBipolar
  return (
    <div
      role='presentation'
      ref={(ref) => { node.current = ref; }}
      className={classNames(styles.sliderBipolar, { [styles.transitioning]: transitioning })}
      onClick={handleClick}
    >
      { /* LINE */ }
      <div className={styles.lineLayer}>
        <div className={styles.line} />
      </div>

      { /* BARS */ }
      <div className={styles.barsLayer}>
        <div
          className={styles.bar}
          style={{ transform: `scaleX(${knobValue / to})` }}
        />
        <div className={styles.barCenter} />
      </div>

      { /* KNOB */ }
      <div
        className={classNames(styles.knobLayer, { [styles.touched]: Number.isFinite(knobValue) })}
        style={{ left: `${((knobValue / to) + 1) * 50}%` }}
      >
        <div
          role='presentation'
          className={styles.knob}
          onClick={handleDoubleClickStop}
          onDoubleClick={handleDoubleClick}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        >
          <div className={styles.knobBackground}>
            <div className={styles.knobArrows}>
              <ArrowLeft />
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderBipolar;
