// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './UpgradeTrial.module.scss';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { millisecondsToDays } from 'utils/dateTools';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectPlanIsTrial,
  selectPlanTrialExpiration,
  selectUserGroup,
} from 'features/framework/storeNext/configurationSlice';


// COMPONENT: UpgradeTrial
const UpgradeTrial = () => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const planIsTrial = useSelector(selectPlanIsTrial);
  const expiration = useSelector(selectPlanTrialExpiration);
  const userGroup = useSelector(selectUserGroup);


  // RENDER: UpgradeTrial
  if (!planIsTrial) {
    return null;
  }

  return (
    <div className={styles.upgradeTrial}>
      <div className={styles.text}>
        { translate('trial_ends_text') }
        <span className={styles.highlight}>
          { ` ${millisecondsToDays(expiration)} ${translate('in_days')}` }
        </span>
      </div>

      { userGroup === 'admin' && (
        <Link to='/upgrade-from-trial'>
          <Button looks='primary' size='S'>
            { translate('subscribe') }
          </Button>
        </Link>
      ) }
    </div>
  );
};

export default UpgradeTrial;
