// REACT, STYLE, STORIES & COMPONENT
import React, {
  useEffect, useState, useRef, useMemo, useCallback,
} from 'react';
import styles from './MyStrengthProfile.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// OTHER COMPONENTS
import {
  InfoCard,
  PillGroup,
  ButtonCircular,
  SkeletonFlexible,
} from 'ui/basic';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';
import {
  Big5Result, CliftonResult,
  CompetenciesResult, NineLevelsResult, Peer360Result,
  PotentialResult, RmpResult, SkillsResult,
  WorkPreferencesResult,
  ExportAssessmentResult,
  AssessmentHistory,
} from 'ui/molecules';
import {
  RecurringAssessmentControl,
} from 'features/framework/pages/MyProfileWellBeing/BalancedYou/components/RecurringAssessmentControl';
import RmpImport from 'features/framework/pages/Overview/RmpImport';


// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint, useHeightAnimationStyle } from 'utils/hooks';
import {
  ASSESSMENT_TYPES,
  ASSESSMENT_RECURRENCE_TYPES as RECURRENCE_TYPES,
  ASSESSMENTS_INFO_CONTENT_KEYS,
  ASSESSMENTS_INFO_TITLE_KEYS,
} from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentHistory, getAssessmentResult } from 'store/actions';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import { getFirstName, getLastName } from 'store/selectors/currentUser';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';
import AssessmentCarousel from 'ui/basic/containers/AssessmentCarousel/AssessmentCarousel';
import { getMappedNineLevelsResults } from 'utils/assessment';

// CONFIG & DATA
const Config = {
  assessmentHistoryLimit: 20,
};


// COMPONENT: MyStrengthProfile
const MyStrengthProfile = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();
  const { isXs } = useBreakpoint();
  const [ controlWrapperRef, controlWrapperStyle ] = useHeightAnimationStyle();

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const recommendations = useSelector(selectRecommendationsUnarchived);
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsNonHealth);
  const assessmentsLoading = useSelector(fromAssessmentsSelectors.selectAssessmentsLoading);
  const scrollableRef = useRef();

  const [ selectedItem, setSelectedItem ] = useState();
  const [ teasersPageLeftAvailable, setTeasersPageLeftAvailable ] = useState(false);
  const [ teasersPageRightAvailable, setTeasersPageRightAvailable ] = useState(false);
  const [ teasersPagePosition, setTeasersPagePosition ] = useState(0);
  const [ rmpImportModalVisible, setRmpImportModalVisible ] = useState(false);
  const [ historyIndex, setHistoryIndex ] = useState(0);

  const items = assessmentsFromStore
  .filter(({ id, stages, resultsAvailable }) => {
    if (resultsAvailable) {
      return true;
    }

    return !!(id === ASSESSMENT_TYPES.RMP
      && stages.every(({
        length,
        questionsAnswered,
      }) => questionsAnswered > 0 && length === questionsAnswered));
  })
  .map(({ id, title: label }) => ({ id, label }));

  useEffect(() => {
    if (!selectedItem && items.length > 0 && !assessmentsLoading) {
      const route = new URLSearchParams(window.location.search).get('route');
      const index = Math.max(0, items.findIndex(({ id }) => id === route));
      setSelectedItem(items[index]);
    }
  }, [ items, selectedItem, assessmentsLoading ]);

  const [ assessmentResultRequested, setAssessmentResultRequested ] = useState(false);
  useEffect(() => {
    if (assessmentResultRequested || !selectedItem) {
      return;
    }

    const thisAssessment = assessmentsFromStore.find((assessment) => assessment.id === selectedItem.id) || {};
    if (thisAssessment.results?.length > 0) {
      return;
    }

    setAssessmentResultRequested(true);
    dispatch(getAssessmentResult(selectedItem.id));
  }, [
    dispatch,
    assessmentResultRequested,
    assessmentsFromStore,
    selectedItem,
  ]);

  // EXPORT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ diagramType, setDiagramType ] = useState();
  const [ diagramRenderProcessing, setDiagramRenderProcessing ] = useState(false);
  const openExportModal = (thisDiagramType) => {
    setDiagramType(thisDiagramType);
    setDiagramRenderProcessing(true);

    setTimeout(() => {
      setDiagramRenderProcessing(false);
    }, 1000);
  };

  const cleanupExportModal = () => {
    setDiagramType();
  };

  const selectedAssessment = assessmentsFromStore.find((a) => a.id === selectedItem?.id);

  const getAssessmentResults = () => {
    if (!selectedAssessment) {
      return undefined;
    }

    const assessmentType = selectedAssessment.id;

    let assessmentResults;
    if (selectedAssessment.userAvailable === undefined || selectedAssessment.userAvailable) {
      assessmentResults = selectedAssessment.results;
    }

    if (selectedAssessment.history?.results && historyIndex) {
      assessmentResults = selectedAssessment.history.results[historyIndex].results;
    }

    if (!assessmentResults) {
      return undefined;
    }

    if (!assessmentResults.length) {
      return selectedAssessment.managerControlled ? [] : undefined;
    }

    if (assessmentType === ASSESSMENT_TYPES.NINE_LEVELS) {
      const [ mappedResults ] = getMappedNineLevelsResults(assessmentResults);
      return mappedResults;
    }

    return assessmentResults;
  };

  const hasPrevResults = Object.values(selectedAssessment?.history?.results ?? []).some(Boolean);
  const hideRecurring = selectedAssessment?.recurrenceType === RECURRENCE_TYPES.RUN
  && !hasPrevResults
  && selectedAssessment?.locked;

  const [ selectedItemTitle, setSelectedItemTitle ] = useState();
  const [ selectedItemDescription, setSelectedItemDescription ] = useState();
  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    setSelectedItemTitle(translate(
      ASSESSMENTS_INFO_TITLE_KEYS[selectedItem.id] || selectedAssessment?.title,
    ));
    setSelectedItemDescription(translate(
      ASSESSMENTS_INFO_CONTENT_KEYS[selectedItem.id] || selectedAssessment?.description,
    ));
  }, [
    translate,
    selectedItem,
    selectedAssessment,
  ]);

  // RECURRING ASSESSMENT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const assessmentHistoryTotal = selectedAssessment?.history?.assessmentStatistics?.totalResults;
  const assessmentHistoryResults = useMemo(
    () => [ ...selectedAssessment?.history?.results || [] ].reverse(),
    [ selectedAssessment?.history ],
  );

  const [ monthlyMode, setMonthlyMode ] = useState();
  useEffect(() => {
    if (monthlyMode !== undefined || !assessmentHistoryResults.length) {
      return;
    }

    const diffs = [];
    for (let i = 0; i < 5; i += 1) {
      const first = assessmentHistoryResults[i];
      const second = assessmentHistoryResults[i + 1];
      if (first && second) {
        diffs.push(second.date - first.date);
      }
    }

    const average = diffs.reduce((acc, current) => acc + current, 0) / diffs.length;
    const averageInDays = average / 60 / 60 / 24; // convert timespan to days

    setMonthlyMode(averageInDays <= 90); // 90 days = 3 months
  }, [ assessmentHistoryResults, monthlyMode ]);

  // Fetch assessment history
  const [ historyOffset, setHistoryOffset ] = useState(0);
  const historyOffsets = useRef([]);
  const recurring = [ RECURRENCE_TYPES.RUN, RECURRENCE_TYPES.FREQUENCY, RECURRENCE_TYPES.MANUAL ]
  .includes(selectedAssessment?.recurrenceType);

  useEffect(() => {
    if (!selectedAssessment?.id || historyOffsets.current.includes(historyOffset)) {
      return;
    }

    if (recurring) {
      historyOffsets.current.push(historyOffset);
      dispatch(getAssessmentHistory(selectedAssessment.id, { offset: historyOffset }));
    }
  }, [
    dispatch,
    selectedAssessment?.id,
    recurring,
    historyOffset,
  ]);

  // RENDER: result component
  const renderResultComponent = (options = {}) => {
    const { exportView, diagramId } = options;

    const assessmentType = selectedItem.id;
    const openReport = Boolean(Number(new URLSearchParams(window.location.search).get('openReport')));

    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return (
          <div id={diagramId}>
            <Big5Result
              id={diagramId}
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
              openReport={openReport}
            />
          </div>
        );
      case ASSESSMENT_TYPES.POTENTIAL:
        return (
          <div id={diagramId}>
            <PotentialResult
              showCallout
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        );
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return (
          <div id={diagramId}>
            <WorkPreferencesResult
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        );
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
        return (
          <div id={diagramId}>
            <CompetenciesResult
              assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        );
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <div id={diagramId}>
            <CompetenciesResult
              assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
            />
          </div>
        );
      case ASSESSMENT_TYPES.RMP:
        return (
          <div id={diagramId}>
            <RmpResult
              id={ASSESSMENT_TYPES.RMP}
              results={getAssessmentResults(assessmentType)}
              showPendingState
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
              openReport={openReport}
            />
          </div>
        );
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return (
          <div id={diagramId}>
            <NineLevelsResult
              id={ASSESSMENT_TYPES.NINE_LEVELS}
              results={getAssessmentResults(assessmentType)}
              signatureValues={
                selectedAssessment?.results?.filter((resultItem) => resultItem.id.includes('highvalues')) || []
              }
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
              openReport={openReport}
            />
          </div>
        );
      default: {
        const thisAssessment = assessmentsFromStore.find((a) => a.id === assessmentType);
        if (thisAssessment?.render_results === 'clifton') {
          return (
            <div id={diagramId}>
              <CliftonResult strengths={getAssessmentResults(assessmentType)} />
            </div>
          );
        }

        let noDataText;
        if (getAssessmentResults(assessmentType)?.length === 0 && selectedAssessment.managerControlled) {
          // if the results for manager-controlled assessments is [], it means the sharedWithUser flag is false.
          noDataText = translate('managed_result_info', [
            '{{assessmentId}}',
            selectedAssessment.title,
          ]);
        }

        if (thisAssessment?.peerAssessment) {
          return (
            <div id={diagramId}>
              <Peer360Result
                historyIndex={historyIndex}
                assessmentData={selectedAssessment}
                assessmentResults={getAssessmentResults(assessmentType)}
                showExportIcon
                showInfoTitle={false}
                exportView={exportView}
                onExport={() => openExportModal(assessmentType)}
                openReport={openReport}
                noDataText={noDataText}
              />
            </div>
          );
        }

        return (
          <div id={diagramId}>
            <SkillsResult
              historyIndex={historyIndex}
              assessmentType={assessmentType}
              results={getAssessmentResults(assessmentType)}
              showExportIcon
              showInfoTitle={false}
              exportView={exportView}
              onExport={() => openExportModal(assessmentType)}
              noDataText={noDataText}
            />
          </div>
        );
      }
    }
  };

  const onPageUpdate = useCallback(({
    newPagePosition, totalPagesCount, cellsCountPerPage, pageToLeft,
  }) => {
    if (pageToLeft && totalPagesCount) {
      const nextPagePosition = totalPagesCount - newPagePosition;
      const nextPageDataCount = nextPagePosition * cellsCountPerPage;
      const offset = historyOffset + Config.assessmentHistoryLimit;

      if (nextPageDataCount > assessmentHistoryResults?.length && offset < assessmentHistoryTotal) {
        setHistoryOffset(offset);
      }
    }
  }, [
    historyOffset,
    assessmentHistoryResults?.length,
    assessmentHistoryTotal,
  ]);

  if (assessmentsLoading) {
    return (
      <div className={classNames(styles.myStrengthProfile)}>
        <div className='bluTypeXs'>
          { translate('strength_profile_nav') }
        </div>
        <div className={classNames(styles.loading)}>
          <SkeletonFlexible noHeader repeat={9} />
        </div>
      </div>
    );
  }

  if (!selectedItem) {
    return (
      <div className={classNames(styles.myStrengthProfile)}>
        <div className='bluTypeXs'>
          { translate('strength_profile_nav') }
        </div>

        <div className={styles.assessmentsInfo}>
          <div className={styles.assessmentTeasers}>
            <div className={styles.titleBlock}>
              <div className={styles.title}>
                { translate('strength_profile_start_assessment') }
              </div>

              <div className={styles.scrollingButtons}>
                <ButtonCircular
                  icon='ArrowLeft'
                  looks='primary'
                  disabled={!teasersPageLeftAvailable}
                  onClick={() => scrollableRef.current.pageLeft()}
                />
                <ButtonCircular
                  icon='ArrowRight'
                  looks='primary'
                  disabled={!teasersPageRightAvailable}
                  onClick={() => scrollableRef.current.pageRight()}
                />
              </div>
            </div>
            <AssessmentCarousel
              scrollableRef={scrollableRef}
              assessments={assessmentsFromStore}
              onPaginationAvailabilities={([
                pageLeftAvailable,
                pageRightAvailable,
                pagePosition,
              ]) => {
                setTeasersPageLeftAvailable(pageLeftAvailable);
                setTeasersPageRightAvailable(pageRightAvailable);
                if (pagePosition >= 0
                  && pagePosition !== teasersPagePosition
                  && pagePosition > teasersPagePosition
                ) {
                  setTeasersPagePosition(pagePosition);
                }
              }}
              setRmpImportModalVisible={setRmpImportModalVisible}
              includeGarminTeaser={false}
            />
          </div>
        </div>

        { /* RMP IMPORT */ }
        { rmpImportModalVisible && (
          <RmpImport onClose={() => setRmpImportModalVisible(false)} />
        ) }
      </div>
    );
  }

  // RENDER: MyStrengthProfile
  return (
    <div className={classNames(styles.myStrengthProfile)}>
      <span className='bluTypeXs'>{ translate('strength_profile_nav') }</span>

      { items.length > 1 && (
        <PillGroup
          activeIndex={items.findIndex((item) => item.id === selectedItem.id)}
          looks='primary'
          pillItems={items}
          wrap={!isXs}
          onPillClick={(pillItem) => {
            if (pillItem.id === selectedItem.id) {
              return;
            }
            historyOffsets.current = [];
            setHistoryIndex(0);
            setSelectedItem(pillItem);
            setAssessmentResultRequested(false);

            history.push(`/my-profile/strength-profile?route=${pillItem.id}`);
          }}
        />
      ) }

      <div className={styles.gridContainer}>
        <div className={styles.left}>
          { renderResultComponent() }

          { assessmentHistoryResults.length > 1 && (
            <AssessmentHistory
              results={assessmentHistoryResults}
              range={[ selectedAssessment?.renderReferenceMin, selectedAssessment?.renderReferenceMax ]}
              historyIndex={Math.abs(historyIndex)}
              monthlyMode={monthlyMode}
              pageCountTotal={assessmentHistoryTotal}
              onPageUpdate={onPageUpdate}
            />
          ) }
        </div>

        <div className={styles.right}>
          { recurring && !hideRecurring && (
            <div
              ref={controlWrapperRef}
              style={controlWrapperStyle}
            >
              <RecurringAssessmentControl
                assessment={selectedAssessment}
                historyIndex={historyIndex}
                onHistoryChange={setHistoryIndex}
              />
            </div>
          ) }

          <InfoCard
            title={selectedItemTitle}
            content={selectedItemDescription}
          />

          { recommendations
          .filter((el) => el.module === selectedItem.id)
          .map((el) => <RecommendationCard key={el.id} {...el} />) }
        </div>
      </div>

      { /* EXPORT MODAL */ }
      { diagramType && (
        <ExportAssessmentResult
          diagramType={diagramType}
          user={{
            firstName,
            lastName,
          }}
          diagramRenderProcessing={diagramRenderProcessing}
          onClose={cleanupExportModal}
        >
          { renderResultComponent({ exportView: true, diagramId: `export-${diagramType}` }) }
        </ExportAssessmentResult>
      ) }
    </div>
  );
};

export default MyStrengthProfile;
