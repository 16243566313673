// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useMemo } from 'react';
import styles from './Peer360Manage.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// OTHER COMPONENTS
import {
  Button,
  Link,
  Modal,
  Toast,
} from 'ui/basic';
import ProgressCircle from 'ui/basic/micro-ui/ProgressCircle';
import { SkeletonFlexible } from 'ui/basic/micro-ui/SkeletonFlexible';
import { AddPeersModal } from './AddPeersModal';
import { EditPeerModal } from './EditPeerModal';

// UTILS
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';
import { sortAlphabetically } from 'utils/strings';
import { timestampToFullDate } from 'utils/dateTools';
import { apiNext } from 'apiNext';
import {
  getIsExternalSystemIntegrationSupported,
  replaceCoachHubTarget,
} from 'features/+coachHub/utils/localStorage';

// STORE NEXT
import {
  selectCompany,
  selectIsPeerControlled,
  selectIsPeerController,
} from 'store/selectors/configuration';
import { listAssessments } from 'store/actions';

// COMPONENT: Peer360Manage
const Peer360Manage = ({
  assessment,
  employee,
  allowInviteInternalUsers = true,
  viewIsModal = true,
  onSetup,
}) => {
  const {
    id: employeeId,
    name: employeeName,
    mail: employeeMail,
  } = employee ?? {};

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  // SELECTORS
  const company = useSelector(selectCompany);
  const isPeerControlled = useSelector(selectIsPeerControlled);
  const isPeerController = useSelector(selectIsPeerController);

  const externalSystemSupported = getIsExternalSystemIntegrationSupported();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ showCloseAssessmentModal, setShowCloseAssessmentModal ] = useState(false);
  const [ showReminderToast, setShowReminderToast ] = useState(false);
  const [ peerEditModalData, setPeerEditModalData ] = useState(null);
  const [ showErrorToast, setShowErrorToast ] = useState(false);
  const [ isSendingReminder, setIsSendingReminder ] = useState(false);
  const [ isClosingAssessment, setIsClosingAssessment ] = useState(false);
  const [ assessmentProgress, getAssessmentProgress ] = useState();
  const [ sentReminders, setSentReminders ] = useState(new Set());
  const [ toastedReminders, setToastedReminders ] = useState(new Set());

  const apiParams = useMemo(() => (employeeId ? { user: employeeId } : undefined), [ employeeId ]);
  const peerGroups = assessment?.peerGroups;
  const selfProgress = assessmentProgress?.peerProgress.self?.progress;
  const selfCompletedAt = assessmentProgress?.peerProgress.self?.completedAt;
  const missingPeersMails = assessmentProgress && Object.values(assessmentProgress.peerProgress)
  .map(({ peers = [] }) => peers.filter((el) => !el.completed).map((el) => el.mail))
  .flat();
  const noPeerResults = assessmentProgress && Object.values(assessmentProgress.peerProgress)
  .every(({ completed }) => !completed);
  const allPeers = Object.values(assessmentProgress?.peerProgress ?? {})
  .map(({ peers }) => peers)
  .flat()
  .filter(Boolean);
  const crossValues = allPeers.map((el) => ({ ...el, value: el.mail }))
  .concat({ value: employeeMail || localStorage.getItem('mail') }); // prevent user from selecting themself

  /**
   * Fetch assessmentProgress
   */
  useEffect(() => {
    apiNext.get(`/core/assessments/${assessment.id}/progress`, apiParams)
    .then((data) => {
      // Exit page if assessment completed
      if (data.progress === 100 && assessment?.completed) {
        history.push('/');
        return;
      }

      // Map user mails
      Object.values(data.peerProgress).forEach((peerGroup) => {
        peerGroup.peers?.forEach((peer) => {
          if (peer.user) {
            // eslint-disable-next-line no-param-reassign
            peer.mail = peer.user.mail;
          }
        });
      });

      getAssessmentProgress(data);
    })
    .catch((error) => {
      console.error(error.message);
      setShowErrorToast(true);
    });
  }, [ history, assessment, employeeId, apiParams ]);

  /**
   * Api call to send reminders to a list of mail addresses.
   *
   * @param {Array[String]} peersMails List of mails where the reminder should be sent
   */
  const sendReminders = (peersMails) => {
    // Convert user mails to ids
    const peers = peersMails.map((mail) => {
      const userId = allPeers.find((el) => el.mail === mail)?.user?.id;
      return userId ?? mail;
    });

    setIsSendingReminder(true);
    apiNext.post(`/core/assessments/${assessment.id}/invite`, { peers }, apiParams)
    .then(() => {
      setShowReminderToast(true);
      setSentReminders((state) => new Set([ ...state, ...peersMails ]));
    })
    .catch((error) => {
      console.error(error.message);
      setShowErrorToast(true);
    })
    .finally(() => {
      setIsSendingReminder(false);
    });
  };

  /**
   * Close the assessment and redirect to profile.
   */
  const closePeerAssessment = () => {
    setIsClosingAssessment(true);
    apiNext.post(`/core/assessments/${assessment.id}/close`, undefined, apiParams)
    .then(() => {
      dispatch(listAssessments());
      if (externalSystemSupported) {
        replaceCoachHubTarget('profile', '/my-profile');
        return;
      }

      if (employeeId) {
        history.push(`/employees/${employeeId}/profile`);
      } else {
        history.push(`/my-profile/strength-profile?route=${assessment.id}&openReport=1`);
      }
    })
    .catch((error) => {
      console.error(error.message);
      setShowErrorToast(true);
      setShowCloseAssessmentModal(false);
      setIsClosingAssessment(false);
    });
  };

  const handleAssessmentStart = () => {
    if (externalSystemSupported) {
      replaceCoachHubTarget('assessment', `/assessments/${assessment.id}`);
    } else {
      history.push(`/assessments/${assessment.id}`);
    }
  };

  // ADD PEERS MODAL
  const [ selectedPeerKey, setSelectedPeerKey ] = useState();
  const [ showAddPeersModal, setShowAddPeersModal ] = useState(false);
  const [ showPeersAddedToast, setShowPeersAddedToast ] = useState(false);

  // RENDER: completedAt
  const renderDate = (date) => (
    <div className={styles.completedAt}>
      { translate(
        'peer_360_group_manage_completed_on',
        [ '{{date}}', timestampToFullDate(date) ],
      ) }
    </div>
  );

  // RENDER: Peer360Manage
  return (
    <div className={classNames(styles.peer360Manage, { [styles.modal]: viewIsModal })}>
      { /* HEADER */ }
      { viewIsModal && (
        <div className={styles.header}>
          <span>
            { translate(
              'peer_assessment_title',
              [ '{{assessment}}', assessment.title ],
            ) }
          </span>
          <div
            className={styles.close}
            role='presentation'
            onClick={() => history.goBack()}
          >
            { translate('close_lbl') }
          </div>
        </div>
      ) }

      { /* BODY */ }
      <div className={styles.scrollableContent}>
        <div className={styles.gridContent}>
          <div className={styles.main}>
            { employeeName && (
              <div className={styles.preTitle}>
                { translate(
                  'peer_manage_pretitle',
                  [ '{{username}}', employeeName ],
                ) }
              </div>
            ) }
            <div className={styles.title}>
              { translate(
                'peer_manage_title',
                [ '{{assessment}}', assessment.title ],
              ) }
            </div>
            <div className={styles.description}>
              { translate(assessment.customContent?.peer_360_manage_description
                ?? 'peer_360_manage_description_default') }
            </div>

            { !assessmentProgress || !peerGroups
              ? (
                <div className={styles.skeleton}>
                  <SkeletonFlexible repeat={5} />
                </div>
              ) : (
                <>
                  { /* SELF PEER GROUP */ }
                  { !assessment.peerOnly && (
                    <div className={styles.peerGroup}>
                      <div className={styles.titleContainer}>
                        <ProgressCircle
                          progress={(selfProgress === 100 && !selfCompletedAt) ? 99 : selfProgress}
                        />
                        <div className={styles.title}>
                          { translate('peer_360_peer_perspectives_self') }
                        </div>
                      </div>
                      { (selfProgress === 100 && selfCompletedAt) && (
                        <div className={styles.selfCompleted}>
                          { renderDate(assessmentProgress?.peerProgress.self.completedAt) }
                        </div>
                      ) }
                      <div>
                        { translate(assessment.customContent?.peer_360_manage_self_description
                          ?? 'peer_360_manage_self_description') }
                      </div>
                      { (selfProgress < 100 || !selfCompletedAt) && !(isPeerController && employeeId) && (
                        <div className={styles.buttonContainer}>
                          <Button
                            looks='primary'
                            onClick={handleAssessmentStart}
                          >
                            { translate(selfProgress
                              ? 'peer_360_manage_self_continue'
                              : 'peer_360_manage_self_start') }
                          </Button>
                        </div>
                      ) }
                    </div>
                  ) }

                  { /* REMAINING PEER GROUPS */ }
                  { Object.entries(assessmentProgress?.peerProgress)
                  .filter(([ key ]) => key !== 'self')
                  .filter(([ , { invited } ]) => !company.controlledAssessmentFlow || isPeerController
                      || (isPeerControlled && invited))
                  .sort(([ keyA, itemA ], [ keyB, itemB ]) => {
                    // place all empty groups at the bottom, then sort alphabetically
                    if (Boolean(itemA.invited) !== Boolean(itemB.invited)) {
                      return itemB.invited - itemA.invited;
                    }
                    return sortAlphabetically(keyA, keyB);
                  })
                  .map(([ key, {
                    progress,
                    peers = [],
                  } ]) => (
                    <div className={styles.peerGroup} key={key}>
                      <div className={styles.titleContainer}>
                        <ProgressCircle progress={progress} />
                        <div className={styles.title}>
                          { capitalise(peerGroups[key]?.label) }
                        </div>
                      </div>

                      { peers.map(({
                        mail,
                        user,
                        completedAt,
                      }) => (
                        <div
                          className={styles.peer}
                          key={mail}
                        >
                          <div className={styles.icon}>
                            { completedAt
                              ? <IconsSvg.PeopleCheckmark className={styles.completed} />
                              : <IconsSvg.People className={styles.missing} /> }
                          </div>
                          <div className={styles.mail}>
                            { user
                              ? `${user.firstName} ${user.lastName} (${mail})`
                              : mail }
                          </div>
                          { !completedAt && (
                            <div className={styles.status}>
                              <Link onClick={() => {
                                setPeerEditModalData({
                                  mail,
                                  group: capitalise(peerGroups[key]?.label),
                                });
                              }}
                              >
                                <IconsSvg.Edit className={styles.peerEditIcon} />
                                &nbsp;&nbsp;
                                { translate('candidate_attachments_edit') }
                              </Link>
                            </div>
                          ) }
                          <div className={styles.status}>
                            { completedAt
                              ? renderDate(completedAt)
                              : (
                                <Link
                                  onClick={() => sendReminders([ mail ])}
                                  active={!sentReminders.has(mail) && !isSendingReminder}
                                >
                                  <IconsSvg.Send
                                    className={classNames({
                                      [styles.completedAt]: sentReminders.has(mail) || isSendingReminder,
                                    })}
                                  />
                                &nbsp;&nbsp;
                                  { translate('peer_360_group_manage_send_reminder') }
                                </Link>
                              ) }
                          </div>
                        </div>
                      )) }

                      { (!company.controlledAssessmentFlow || isPeerController) && (
                        <div
                          role='presentation'
                          className={classNames(styles.add, {
                            [styles.disabled]: peers.length === peerGroups[key]?.maxPeers,
                          })}
                          onClick={() => {
                            setSelectedPeerKey(key);
                            setShowAddPeersModal(true);
                          }}
                        >
                          <IconsSvg.Plus />
                          <span className='bluTypeCopy'>
                            { translate('add_peers_lbl', [
                              '{{peerGroup}}', capitalise(peerGroups[key]?.label),
                            ]) }
                          </span>
                        </div>
                      ) }
                    </div>
                  )) }

                  <div className={styles.bottom}>
                    <Button
                      looks={missingPeersMails.length === sentReminders.size ? 'tertiary' : 'secondary'}
                      onClick={() => sendReminders(missingPeersMails.filter((mail) => !sentReminders.has(mail)))}
                      disabled={isSendingReminder || missingPeersMails.length === sentReminders.size}
                    >
                      { translate('peer_360_group_manage_send_reminder_all') }
                    </Button>
                    { !isPeerControlled && (
                      <Button
                        looks='primary'
                        disabled={(selfProgress !== 100 || !selfCompletedAt) || noPeerResults}
                        onClick={() => setShowCloseAssessmentModal(true)}
                      >
                        { translate('peer_360_group_manage_close_now') }
                      </Button>
                    ) }
                  </div>
                </>
              ) }
          </div>
        </div>
      </div>

      { /* FLOATERS */ }
      { showCloseAssessmentModal && (
        <Modal
          header={translate('peer_360_group_close_confirm_title')}
          secondaryButtonTitle={translate('cancel_lbl')}
          redButtonTitle={translate('peer_360_group_manage_close_now')}
          closeOnConfirm={false}
          onClose={() => setShowCloseAssessmentModal(false)}
          onConfirm={closePeerAssessment}
          redButtonDisabled={isClosingAssessment}
        >
          { translate(assessment.customContent?.peer_360_group_close_confirm_description
            ?? 'peer_360_group_close_confirm_description') }
        </Modal>
      ) }

      { showAddPeersModal && (
        <AddPeersModal
          peerProgress={assessmentProgress?.peerProgress?.[selectedPeerKey]}
          peerGroup={{
            id: selectedPeerKey,
            ...peerGroups?.[selectedPeerKey],
          }}
          crossValues={crossValues}
          allowInviteInternalUsers={allowInviteInternalUsers}
          onAdd={() => {
            setShowPeersAddedToast(true);
            setShowAddPeersModal(false);
            onSetup();
          }}
          onClose={() => setShowAddPeersModal(false)}
          employeeId={employeeId}
        />
      ) }

      { showReminderToast && (
        <Toast
          onClose={() => {
            setShowReminderToast(false);
            // Keep track of mails that have been visualized by the user in the toast
            // so they don't show up if the user sends other invites later
            setToastedReminders(sentReminders);
          }}
          headline={translate('invite_emp_success_title')}
        >
          <div className={styles.reminderToast}>
            { translate('peer_360_group_manage_toast_content') }
            { [ ...sentReminders ].filter((el) => !toastedReminders.has(el)).join(', ') }
          </div>
        </Toast>
      ) }

      { showPeersAddedToast && (
        <Toast
          onClose={() => {
            setShowPeersAddedToast(false);
          }}
          headline={translate('invite_emp_success_title')}
        >
          { translate('peers_added_success') }
        </Toast>
      ) }

      { showErrorToast && (
        <Toast onClose={() => setShowErrorToast(false)}>
          { translate('api_error_generic') }
        </Toast>
      ) }

      { Boolean(peerEditModalData) && (
        <EditPeerModal
          {...peerEditModalData}
          crossValues={crossValues}
          onClose={() => setPeerEditModalData(null)}
          onUpdate={onSetup}
          assessmentId={assessment.id}
          employeeId={employeeId}
          canFetchUsers={!externalSystemSupported}
        />
      ) }
    </div>
  );
};

export default Peer360Manage;
