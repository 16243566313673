import { mapQuestions } from 'utils/assessment';

// CONFIG & DATA
export const initialState = {
  // config
  id: '',
  userId: '',
  title: '',

  // error handling
  error: null,

  // navigation
  allowBackNavigation: true,
  allowBackNavigationOnlyOncePerQuestion: false,
  hasNavigatedBack: false,
  highestQuestionIndex: 0,

  // allowForwardNavigation
  allowForwardNavigation: false,
  skippedQuestionsPage: null,
  skippedQuestionsMode: false, // prevents forward navigation and navigation back
  // further than skippedQuestionsModeStartIndex
  skippedQuestionsModeStartIndex: 0,
  skippedQuestionsPagesOriginal: null,

  // loadingPage
  loadingPage: null,

  // intermissions: [], // passed in via config but not used in reducer

  // assessment state
  questionIndex: 0,
  lastQuestionIndex: 0,
  isEnd: false,
  started: false,
  animationCount: 0,

  // pages, questions, answers
  pages: [],
  answers: {},

  // progress
  progress: 0, // 0 - 100
  progressPagesTotalCount: 0,

  // timing
  clickBlock: false,
  lastTime: 0,
  questionTimes: [],
};

export const init = (overrideState = {}) => ({
  ...initialState,
  ...overrideState,
});

const getProgressPages = (pages) => pages.filter((p) => !p.invisible && (!p.isIntermission || p.countAsProgress));

export const getProgressByIndex = (pages, questionIndex) => {
  const pagesPassedCount = getProgressPages(pages.slice(0, questionIndex + 1)).length;
  const pagesTotalCount = getProgressPages(pages).length
    || 1; // prevent division by 0;
  return (pagesPassedCount / pagesTotalCount) * 100;
};

export const getProgressByAnswers = (pages, answers) => {
  const pagesAnsweredCount = Object.values(answers).filter((v) => v !== undefined).length;
  const pagesTotalCount = getProgressPages(pages).length
    || 1; // prevent division by 0;
  return (pagesAnsweredCount / pagesTotalCount) * 100;
};

export const getQuestionsFromState = (state) => {
  const questionsPages = state.pages.filter((page) => !page.isIntermission && !page.invisible);

  const questionsMap = {};
  questionsPages.forEach((questionPage) => {
    questionsMap[questionPage.id] = questionPage;
  });
  const uniqueQuestionsPages = Object.keys(questionsMap).map((questionsKey) => questionsMap[questionsKey]);

  return uniqueQuestionsPages;
};

// add unanswered questions to originalPages
// puts last page at the end of new pages with unanswered questions
// adds skippedQuestionsPage
export const addUnansweredQuestions = (originalPages, unansweredQuestions, skippedQuestionsPage) => {
  const pages = [ ...originalPages ];
  pages.splice(pages.length - 1, 0, { isIntermission: true, showBackArrow: true, ...skippedQuestionsPage }, ...unansweredQuestions);
  return pages;
};


export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return {
        ...initialState,
      };
    }
    case 'override': {
      const overrideState = action.payload;
      return {
        ...initialState,
        ...overrideState,
      };
    }
    case 'setClickBlock': {
      const clickBlock = true;
      return {
        ...state,
        clickBlock,
      };
    }
    case 'unsetClickBlock': {
      const clickBlock = false;
      return {
        ...state,
        clickBlock,
      };
    }
    // Update answer in state without triggering any advancement
    case 'updateAnswer': {
      if (!action?.payload) {
        return state;
      }

      const { payload: answer } = action;
      const currentPage = state.pages[state.questionIndex];
      const { id: currentPageId } = currentPage;

      const cachedAnswer = state.answers[currentPageId];
      // track answers
      const newAnswer = answer !== undefined
        ? answer
        : cachedAnswer;
      const answers = currentPageId
        ? { ...state.answers, [currentPageId]: newAnswer }
        : { ...state.answers };

      return {
        ...state,
        answers,
      };
    }
    case 'start': {
      const lastTime = action.payload || Date.now();
      const started = true;
      const progressPagesTotalCount = getProgressPages(state.pages).length;
      const clickBlock = true;

      return {
        ...state,
        progressPagesTotalCount,
        lastTime,
        started,
        clickBlock,
      };
    }
    case 'next': {
      const { answer, time, animationTime } = action.payload || {};
      const lastTime = time;

      // check: prevent answers during clickBlock
      if (state.clickBlock) {
        return state;
      }

      // check: boundaries, if at boundary => prevent action
      if (state.questionIndex >= state.pages.length - 1) {
        return state;
      }
      // check: boundaries, if going to boundary => set isEnd
      let isEnd = false;
      if (state.questionIndex + 1 === state.pages.length - 1) {
        isEnd = true;
      }


      // check: no answer for question? => prevent action
      const currentPage = state.pages[state.questionIndex];
      const currentPageId = currentPage.id;
      const { isIntermission } = currentPage;
      const cachedAnswer = state.answers[currentPageId];
      if (currentPageId
        && !isIntermission
        && answer === undefined
        && cachedAnswer === undefined
        && (!state.allowForwardNavigation || state.skippedQuestionsMode)
      ) {
        return state;
      }

      // check: skip nextPage if it has hideOnBackNavigation and hideOnBackNavigationWasSkipped
      let nextIndex = state.questionIndex + 1;
      const nextPage = state.pages[nextIndex];
      if (nextPage.hideOnBackNavigation && nextPage.hideOnBackNavigationWasSkipped && state.pages[state.questionIndex + 2]) {
        nextIndex = state.questionIndex + 2;
      }

      // let nextIndex = state.questionIndex + 1;
      // update question indices
      const questionIndex = nextIndex;
      const lastQuestionIndex = state.questionIndex;
      let highestQuestionIndex = questionIndex;
      if (state.highestQuestionIndex > questionIndex) {
        highestQuestionIndex = state.highestQuestionIndex;
      }
      let { hasNavigatedBack } = state;
      if (questionIndex > state.highestQuestionIndex) {
        hasNavigatedBack = false;
      }

      // track answers
      const newAnswer = answer !== undefined
        ? answer
        : cachedAnswer;
      const answers = currentPageId && !isIntermission
        ? { ...state.answers, [currentPageId]: newAnswer }
        : { ...state.answers };

      // track progress
      let progress;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(state.pages, questionIndex - 1);
      } else {
        progress = getProgressByAnswers(state.pages, answers);
      }

      // track time of question
      const questionTimes = [ ...state.questionTimes ]; // copy
      const questionTime = [ ...(questionTimes[lastQuestionIndex] || []) ];
      const totalTime = time - state.lastTime;
      questionTime.push({
        totalTime,
        animationTime,
        answer,
      });
      questionTimes[lastQuestionIndex] = questionTime;

      // isEnd: check unanswered questions
      let { pages } = state;
      let { skippedQuestionsMode } = state;
      let { skippedQuestionsModeStartIndex } = state;
      let { skippedQuestionsPagesOriginal } = state;
      let { error } = state;

      if (isEnd || (state.skippedQuestionsPagesOriginal && (questionIndex === state.skippedQuestionsPagesOriginal.length - 1))) {
        isEnd = false; //  set isEnd only when allQuestionsAreAnswered
        const unansweredQuestions = getQuestionsFromState(state).filter(({ id }) => answers[id] === undefined);

        if (!unansweredQuestions.length) {
          isEnd = true;
          // restore pages & show end page, when all questions are answered but we were on skippedQuestionsMode before
          if (state.skippedQuestionsPagesOriginal && questionIndex === state.skippedQuestionsPagesOriginal.length - 1) {
            pages = state.skippedQuestionsPagesOriginal;
          }
        } else if (state.allowForwardNavigation) {
          skippedQuestionsMode = true;
          skippedQuestionsModeStartIndex = state.questionIndex + 1;
          skippedQuestionsPagesOriginal = skippedQuestionsPagesOriginal || pages;
          pages = addUnansweredQuestions(
            skippedQuestionsPagesOriginal,
            unansweredQuestions,
            state.skippedQuestionsPage,
          );
        } else {
          error = { message: 'Error: some answers have been skipped.' };
        }
      }

      if (isEnd && state.allowAnswerSkip) {
        const allQuestionsSkipped = getQuestionsFromState(state).every(({ id }) => !answers[id]);
        if (allQuestionsSkipped) {
          pages.splice(pages.length - 1, 0, state.allQuestionsSkippedPage);
        }
      }

      return {
        ...state,
        // error handling
        error,
        // navigation
        hasNavigatedBack,
        skippedQuestionsMode,
        skippedQuestionsModeStartIndex,
        skippedQuestionsPagesOriginal,
        // indices
        questionIndex,
        lastQuestionIndex,
        highestQuestionIndex,
        isEnd,
        animationCount: state.animationCount + 1,
        // pages
        pages,
        // answers
        answers,
        // progress
        progress,
        // timing
        clickBlock: true,
        lastTime,
        questionTimes,
      };
    }
    case 'prev': {
      const lastTime = action.payload || Date.now();

      // prevent navigation during clickBlock
      if (state.clickBlock) {
        return state;
      }

      // prevent navigation: allowBackNavigation disabled
      if (!state.allowBackNavigation) {
        return state;
      }

      // prevent navigation: allowBackNavigation is a number
      let hasNavigatedBack = false;
      if (typeof (state.allowBackNavigation) === 'number') {
        if (state.questionIndex - 1 < state.highestQuestionIndex - state.allowBackNavigation) {
          return state;
        }
        if (state.questionIndex - 1 === state.highestQuestionIndex - state.allowBackNavigation) {
          hasNavigatedBack = true;
        }
        if (state.allowBackNavigationOnlyOncePerQuestion && state.hasNavigatedBack) {
          return state;
        }
      }

      // prevent navigation: boundaries
      if (state.questionIndex <= 0) {
        return state;
      }

      // go back until visible page is found,
      // which is not an intermission that should be hidden on back navigation
      // this is the case for assessments with conditional questions, e.g. IST
      let visiblePageFound = false;
      let prevPageIndex = 1;
      do {
        const prevPage = state.pages[state.questionIndex - prevPageIndex];

        if (prevPage.invisible) {
          // set back to false cause if answer for prev question changes ->
          // the next question can become visible again
          prevPage.invisible = false;
          prevPageIndex += 1;
        } else if (prevPage.isIntermission && prevPage.hideOnBackNavigation) {
          prevPage.hideOnBackNavigationWasSkipped = true;
          prevPageIndex += 1;
        } else {
          visiblePageFound = true;
        }
      } while (!visiblePageFound);


      const questionIndex = state.questionIndex - prevPageIndex;

      // disable skippedQuestionsMode
      let { skippedQuestionsMode } = state;
      if (state.allowForwardNavigation && skippedQuestionsMode) {
        if (questionIndex < state.skippedQuestionsPagesOriginal.length - 1) {
          skippedQuestionsMode = false;
        } else {
          skippedQuestionsMode = true;
        }
      }

      const lastQuestionIndex = state.questionIndex;

      // track progress
      let progress;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(state.pages, questionIndex - 1);
      } else {
        progress = getProgressByAnswers(state.pages, state.answers);
      }

      const answersSkippedPageIndex = state.pages.findIndex((page) => page.isAnswersSkippedPage);
      if (answersSkippedPageIndex >= 0) {
        state.pages.splice(answersSkippedPageIndex, 1);
      }

      return {
        ...state,
        // navigation
        hasNavigatedBack,
        skippedQuestionsMode,
        // indices
        questionIndex,
        lastQuestionIndex,
        isEnd: false,
        animationCount: state.animationCount + 1,
        // progress
        progress,
        // time
        lastTime,
      };
    }

    case 'shiftOpenQuestionsAtEnd': {
      const {
        openQuestions = [],
      } = action.payload;
      const missedPages = state.pages.filter((el) => openQuestions.includes(el.id));
      const newPages = [ ...state.pages ].filter((el) => !openQuestions.includes(el.id));
      newPages.splice(newPages.length - 1, 0, ...missedPages);
      const newQuestionIndex = newPages.length - missedPages.length - 1;
      return {
        ...state,
        questionIndex: newQuestionIndex,
        progress: getProgressByIndex(newPages, newQuestionIndex),
        pages: newPages,
      };
    }

    case 'addPages': {
      const { insertAtIndex, pages, replace } = action.payload;

      // check insertAtIndex
      if (Number.isNaN(insertAtIndex)) {
        return state;
      }

      // check: prevent adding at position smaller/equal questionIndex
      if (insertAtIndex <= state.questionIndex) {
        return state;
      }

      let newPages = [ ...state.pages ];
      let lastPage;
      if (replace) {
        lastPage = newPages[newPages.length - 1];
        newPages = newPages.slice(0, insertAtIndex);
      }
      newPages.splice(insertAtIndex, 0, ...pages);

      // keep last page
      if (lastPage) {
        newPages.push(lastPage);
      }

      // track progress
      let progress;
      if (!state.allowForwardNavigation) {
        progress = getProgressByIndex(newPages, state.questionIndex);
      } else {
        progress = getProgressByAnswers(newPages, state.answers);
      }

      return {
        ...state,
        pages: newPages,
        progress,
      };
    }
    case 'retranslatePages': {
      const {
        questionsData = {},
        replacements = {},
        infoTexts = {},
        customContent,
      } = action.payload;

      const newMappedQuestions = mapQuestions(questionsData, replacements);
      const pages = state.pages.map((page) => ({
        ...page,
        ...newMappedQuestions.find(({ id }) => id === page.id),
      }));

      return {
        ...state,
        ...infoTexts,
        customContent,
        pages,
      };
    }
    // overrides answers and moves to the latest unanswered question
    case 'overrideAnswers': {
      const { prevAnswers } = action.payload;

      // create answers override object
      const answers = {};
      prevAnswers.forEach((prevAnswer) => {
        answers[prevAnswer.questionId] = prevAnswer.answer;
      });

      state.pages.forEach((page) => {
        if (page?.conditional && answers[page?.id] === undefined && !page.answerRequired) {
          // eslint-disable-next-line no-param-reassign
          page.invisible = true;
        }
      });

      // find position of last question that has not been answered
      const newQuestionIndex = state.pages.findIndex((el) => !el.isIntermission
      && !el.invisible
      && el.id
      && !(el.id in answers));

      // track progress
      let progress;
      const { pages } = state;
      if (state.allowForwardNavigation) {
        progress = getProgressByAnswers(pages, answers);
      } else {
        progress = getProgressByIndex(pages, newQuestionIndex - 1);
      }

      return {
        ...state,
        questionIndex: newQuestionIndex,
        progress,
        answers,
      };
    }
    case 'skipCurrentPage': {
      const pages = [ ...state.pages ];
      const { questionIndex } = state;
      const step = action.payload.forward ? 1 : -1;

      const progress = getProgressByIndex(pages, questionIndex);
      let isEnd = false;
      if (state.questionIndex + step === state.pages.length - 1) {
        isEnd = true;
      }

      return {
        ...state,
        pages,
        progress,
        questionIndex: questionIndex + step,
        isEnd,
      };
    }
    /**
     * 1. will skip any intermissions starting at state.questionIndex
     *    by removing them from state.pages until it encounters the first question
     * 2. intermissions further back in state.pages array (that come after any questions)
     *    are not affected
     * 3. if state is already on a question no action will be taken
     */
    case 'removeNextIntermissions': {
      // this action can be called during clickblock

      const currentPage = state.pages[state.questionIndex];

      // check: prevent action when current page is question
      if (!currentPage?.isIntermission) {
        return state;
      }

      // check: boundaries, if at boundary => prevent action
      if (state.questionIndex >= state.pages.length - 1) {
        return state;
      }

      let { pages } = state;
      let nextQuestion = pages[state.questionIndex];
      do {
        pages = pages.slice(1);
        nextQuestion = pages[state.questionIndex];
      }
      while (state.questionIndex <= pages.length - 1
        && nextQuestion?.isIntermission
      );

      const progress = getProgressByIndex(state.pages, state.questionIndex - 1);

      return {
        ...state,
        pages,
        progress,
      };
    }
    default: {
      throw new Error(`action.type ${action.type} not handled`);
    }
  }
};

// const getProgress = (({ pages, index, answers, allowForwardNavigation }) => {
//   if (!allowForwardNavigation) {
//     return getProgressByIndex(pages, index);
//   }
//   else {
//     return getProgressByAnswers(pages, answers);
//   }
// });
