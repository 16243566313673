// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './Peer360Report.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentReportNext } from 'ui/molecules/AssessmentReportNext';
import { Introduction } from './sections/Introduction';
import { ResultSummary } from './sections/ResultSummary';
import { NextSteps } from './sections/NextSteps';
import { StrengthsAndDevelopmentAreas } from './sections/StrengthsAndDevelopmentAreas';
import { ResultsInDetail } from './sections/ResultsInDetail';
import { AdditionalInputAndComments } from './sections/AdditionalInputAndComments';
import { CustomReport } from 'ui/molecules/CustomReport';

// UTILS
import { useTranslate } from 'utils/translator';
import { isValid } from 'utils/numbers';

// CONFIG & DATA
// const Config = {};

// COMPONENT: Peer360Report
const Peer360Report = (props) => {
  // PROPS
  const {
    userId,
    assessmentData,
    assessmentResults,
    assessmentResultsAll,
    historyIndex,
    onClose = () => {},
    downloadHandles,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // RENDERS
  const introductionPage = useCallback(
    () => <Introduction assessmentData={assessmentData} />,
    [ assessmentData ],
  );

  const segmentsCount = assessmentData.renderReferenceMin === 0
    ? assessmentData.renderReferenceMax + 1
    : assessmentData.renderReferenceMax;

  const resultSummaryPage = useCallback(() => (
    <ResultSummary
      // show only the dimensions with numeric result value;
      // string result values should be filtered out here
      assessmentResults={assessmentResults.filter((dimension) => isValid(dimension.result))}
      segmentsCount={segmentsCount}
      renderMin={assessmentData.renderReferenceMin}
    />
  ), [ assessmentResults, assessmentData.renderReferenceMax ]);
  const strengthsPage = useCallback(() => (
    <StrengthsAndDevelopmentAreas
      assessmentResults={assessmentResults}
      segmentsCount={segmentsCount}
      renderMin={assessmentData.renderReferenceMin}
    />
  ), [ assessmentResults, assessmentData.renderReferenceMax ]);
  const resultsInDetailPage = useCallback(
    (dimensions) => (
      <ResultsInDetail
        assessmentResults={dimensions}
        segmentsCount={segmentsCount}
        renderMin={assessmentData.renderReferenceMin}
      />
    ),
    [ assessmentData.renderReferenceMin, segmentsCount ],
  );
  const additionalInputPage = (dimensionStringResults) => (
    <AdditionalInputAndComments assessmentResults={dimensionStringResults} />
  );
  const nextStepsPage = () => <NextSteps />;

  // PAGES: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ pages, setPages ] = useState([]);
  useEffect(() => {
    if (!assessmentData || !assessmentResults || pages.length) {
      return;
    }

    const dimensionsWithSubDimensions = assessmentResults.filter((dimension = {}) => (
      dimension.subDimensions?.length > 0
    ));

    const pagesInternal = [
      {
        name: translate('introduction_header_title'),
        content: introductionPage,
      },
      {
        name: translate('peer_360_report_result_summary'),
        content: resultSummaryPage,
      },
      {
        name: translate('peer_360_report_next_steps'),
        content: nextStepsPage,
      },
    ];

    // adding the strengths page
    if (Object.keys(assessmentResults?.[0]?.peerResults).length > 1
      && !assessmentData.peerOnly) {
      pagesInternal.splice(2, 0, {
        name: translate('peer_360_report_strengths'),
        content: strengthsPage,
      });
    }

    // adding the 'Results in detail' page
    if (dimensionsWithSubDimensions.length > 0) {
      pagesInternal.splice(3, 0, {
        name: translate('peer_360_report_detailed_results'),
        content: () => resultsInDetailPage(dimensionsWithSubDimensions),
        subSections: dimensionsWithSubDimensions.map((dimension) => ({
          id: dimension.id,
          name: dimension.name || dimension.id,
        })),
      });
    }

    // adding 'Additional Input & Comments' page
    if (assessmentResults.find((dimension = {}) => Array.isArray(dimension.result)
      || typeof dimension.result === 'string')
    ) {
      const dimensionStringResults = assessmentResults
      .filter((dimension) => (
        Array.isArray(dimension.result) || typeof dimension.result === 'string'))
      .map((dimension) => ({
        ...dimension,
        result: Array.isArray(dimension.result) ? dimension.result : [ dimension.result ],
      }));

      const insertAtIndex = dimensionsWithSubDimensions.length > 0 ? 4 : 3;
      pagesInternal.splice(insertAtIndex, 0, {
        name: translate('peer_360_report_additional_input'),
        content: () => additionalInputPage(dimensionStringResults),
        subSections: dimensionStringResults.map((dimension) => ({
          id: dimension.id,
          name: dimension.name || dimension.id,
        })),
      });
    }

    setPages(pagesInternal);
  }, [
    translate,
    assessmentData,
    assessmentResults,
    pages,
    introductionPage,
    resultSummaryPage,
    strengthsPage,
    resultsInDetailPage,
  ]);


  // RENDER: Peer360Report
  return (
    <div className={classNames(styles.peer360Report)}>
      { assessmentData.customReport
        ? (
          <CustomReport
            historyIndex={historyIndex}
            userId={userId}
            assessment={assessmentData}
            assessmentResults={assessmentResults}
            assessmentResultsAll={assessmentResultsAll}
            onClose={onClose}
            downloadHandles={downloadHandles}
          />
        ) : (
          <AssessmentReportNext
            titleKey={translate('peer_360_report_header_title', [ '{{assessment}}', assessmentData.title ])}
            pages={pages}
            onClose={onClose}
          />
        ) }
    </div>
  );
};

export default Peer360Report;
