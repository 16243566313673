import React, { useState, useEffect, useRef } from 'react';
// component
import styles from './DropDownSubtle.module.scss';
import counterStyles from './DropDownSubtleCounter.module.scss';
// Components
// eslint-disable-next-line import/no-cycle
import { BluCSSTransition, Checkbox } from 'ui/basic';
// Project
import { handleRipple } from 'utils/ripple';
import { ReactComponent as ArrowDown } from 'assets/icons/icn_arrow_down.svg';
// 3rd party
import classNames from 'classnames';


const DropDownSubtle = (props) => {
  // props
  const {
    className,
    options = [],
    activeIndex = 0,
    onChange,
    children,
    selected,
    fullWidthForXs = true,
    disabled = false,
  } = props;

  const isMultipleChoice = Boolean(selected);
  const buttonDisabled = disabled || !options?.length;
  const [ flyoutIsOpen, setFlyoutIsOpen ] = useState(false);
  const [ activeValue, setActiveValue ] = useState(options?.[activeIndex]?.label ?? '');

  // refs
  const menuItemRef = useRef();
  const multiTransitionRef = useRef();
  const flyoutTransitionRef = useRef();

  // effect to set current indicator position
  useEffect(() => {
    setActiveValue(options[activeIndex] && options[activeIndex].label);
  }, [ options, activeIndex, flyoutIsOpen ]);

  // RENDER: FlyoutMenu
  const renderFlyoutMenu = () => {
    if (isMultipleChoice) {
      return (
        <div
          ref={flyoutTransitionRef}
          className={classNames(styles.flyout, styles.multipleChoice)}
        >
          { options.map((option) => (
            <Checkbox
              key={option.value}
              name={option.label}
              checked={selected.has(option.value)}
              onChange={(checked) => {
                const newSelected = new Set([ ...selected ]);
                if (checked) {
                  newSelected.add(option.value);
                } else {
                  newSelected.delete(option.value);
                }
                onChange(newSelected);
              }}
              hasEllipsis
            />
          )) }
        </div>
      );
    }

    return (
      <div
        ref={flyoutTransitionRef}
        className={classNames(styles.flyout, { [styles.fullWidthForXs]: fullWidthForXs })}
      >
        <div className={styles.title}>
          { children }
        </div>
        { options.map((option, index) => (
          <div
            key={option.value}
            role='presentation'
            className={classNames(styles.menuItem, {
              [styles.active]: activeIndex === index,
            })}
            data-test={`DropDownSubtleItem${activeIndex === index ? 'Active' : ''}`}
            onClick={(event) => {
              handleRipple(event, styles.colorPrimary3);
              onChange(index);
              window.setTimeout(() => {
                setFlyoutIsOpen(false);
              }, styles.animationDurationMs);
            }}
          >
            <span>{ option.label }</span>
          </div>
        )) }
      </div>
    );
  };

  // RENDER: DropDownSubtle
  return (
    <div
      ref={(ref) => { menuItemRef.current = ref; }}
      tabIndex='0'
      role='button'
      className={classNames(styles.container, styles[className])}
      data-test='DropDownSubtle'
      onBlur={(event) => {
        if (!menuItemRef.current.contains(event.relatedTarget)) {
          setFlyoutIsOpen(false);
        }
      }}
    >
      { /* BUTTON */ }
      <div
        role='presentation'
        className={classNames(styles.dropDownSubtle, {
          [styles.open]: flyoutIsOpen,
          [styles.disabled]: buttonDisabled,
        })}
        onClick={() => {
          if (!buttonDisabled) {
            setFlyoutIsOpen(!flyoutIsOpen);
          }
        }}
      >
        { isMultipleChoice
          ? (
            <div className={styles.title}>
              { children }
              <BluCSSTransition
                nodeRef={multiTransitionRef}
                in={Boolean(selected.size)}
                classNames={{ ...counterStyles }}
              >
                <div
                  ref={multiTransitionRef}
                  className={styles.counter}
                >
                  { selected.size || '' }
                </div>
              </BluCSSTransition>
            </div>
          ) : (
            <span>
              { activeValue }
            </span>
          ) }
        <ArrowDown />
      </div>

      { /* MENU */ }
      <BluCSSTransition
        nodeRef={flyoutTransitionRef}
        in={flyoutIsOpen}
        classNames={{ ...styles }}
      >
        { renderFlyoutMenu() }
      </BluCSSTransition>
    </div>
  );
};

export default DropDownSubtle;
