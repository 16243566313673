// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState, useRef } from 'react';
import styles from './CustomReport.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { AssessmentReportNext } from 'ui/molecules/AssessmentReportNext';
import {
  Paragraph,
  GapAnalysis,
  PeerBreakdown,
  StringResults,
  TopBottomX,
  SimpleLineDiagram,
  BipolarScaleDiagram,
  PeerGroupDescription,
  SpiderChart,
  PersonalNotes,
} from 'ui/molecules/CustomReport/Blocks';
import { ProgressCircle } from 'ui/basic';

// UTILS
import { markdown } from 'utils/textTools';
import { useTranslate } from 'utils/translator';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentHistory, getAssessmentReport } from 'store/actions';

// STORE
import * as fromEmployeeOverviewSelector from 'store/selectors/employeeOverview';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { getFullName } from 'utils/users';
import { apiNext } from 'apiNext';
import { IconsSvg } from 'assets/icons';
import { useBreakpoint } from 'utils/hooks';

const Config = {
  defaultMargin: 48,
  acceptedFrameColors: [ 'blue', 'grey', 'purple', 'yellow', 'green', 'red' ],
};

const BLOCK_TYPES = {
  PARAGRAPH: 'paragraph',
  GROUP: 'group',
  GAP_ANALYSIS: 'gap-analysis',
  PEER_BREAKDOWN: 'peer-breakdown',
  STRING_RESULTS: 'string-results',
  TOP_BOTTOM_X: 'top-bottom-x',
  SIMPLE_LINE_DIAGRAM: 'simple-line-diagram',
  BIPOLAR_SCALE_DIAGRAM: 'bipolar-scale',
  PEER_GROUP_DESCRIPTION: 'peer-group-description',
  SPIDER_CHART: 'spider-chart',
  PERSONAL_NOTES: 'personal-notes',
};

// COMPONENT: CustomReport
const CustomReport = (props) => {
  // PROPS
  const {
    userId,
    assessment = {},
    assessmentResults = [],
    assessmentResultsAll = [],
    historyIndex = 0,
    downloadHandles,
    onClose,
  } = props;

  const {
    id: assessmentId,
    reportStructure = {},
  } = assessment;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { isL, isXl } = useBreakpoint();

  const [ notes, setNotes ] = useState();

  let user = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const employee = useSelector(fromEmployeeOverviewSelector.getEmployee);
  if (userId) {
    user = employee;
  }
  const username = getFullName(user);
  const resultId = (assessment.history?.results ?? [])[historyIndex]?.id;

  // Fetch structure if missing
  const structureFetched = useRef(false);
  useEffect(() => {
    if (structureFetched.current) {
      return;
    }
    structureFetched.current = true;

    const fetchNotes = async () => {
      try {
        const data = await apiNext.get(
          `/core/assessments/${assessmentId}/notes`,
          { result_id: resultId, user: userId },
        );
        setNotes(data ?? {});
      } catch (error) {
        console.error(error.message);
        setNotes({});
      }
    };
    fetchNotes();

    // Fetch correct report
    if (resultId) {
      dispatch(getAssessmentReport(
        { userId, resultId, id: assessmentId },
      ));
    } else {
      dispatch(getAssessmentHistory(
        assessmentId,
        { userId, offset: historyIndex },
      ));
    }
  }, [ dispatch, assessmentId, userId, historyIndex, resultId ]);

  const setNote = async (noteId, newText) => {
    try {
      await apiNext.post(
        `/core/assessments/${assessmentId}/notes`,
        { [noteId]: newText },
        { result_id: resultId, user: userId },
      );
      setNotes({ ...notes, [noteId]: newText });
    } catch (error) {
      console.error(error);
    }
  };

  // Filter results depending on their type
  const numberAssessmentResults = assessmentResults
  .filter(({ result }) => [ result ].flat().every(Number.isFinite));

  const numberAssessmentResultsAll = assessmentResultsAll
  .filter(({ result }) => [ result ].flat().every(Number.isFinite));

  const stringAssessmentResults = assessmentResults
  .filter(({ result }) => [ result ].flat().every((el) => typeof el === 'string'));

  const stringAssessmentResultsAll = assessmentResultsAll
  .filter(({ result }) => [ result ].flat().every((el) => typeof el === 'string'));

  const buildInlineStyle = (blockLayout = {}) => {
    const { marginTop = 0, marginBottom = 0 } = blockLayout;

    return {
      marginTop: Config.defaultMargin + marginTop,
      marginBottom: Config.defaultMargin + marginBottom,
    };
  };

  // RENDER: download link
  const renderDownloadLink = (side = false) => {
    if (!downloadHandles) {
      return null;
    }

    return (
      <div
        role='button'
        tabIndex={0}
        className={classNames(
          styles.downloadFileLink,
          {
            [styles.disabled]: downloadHandles.downloading,
            [styles.side]: side,
          },
        )}
        onClick={downloadHandles.handleDownload}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            downloadHandles.handleDownload();
          }
        }}
      >
        { downloadHandles.downloading
          ? (
            <div className={styles.downloadLoader}>
              <ProgressCircle progress={20} loading />
            </div>
          ) : <IconsSvg.DownloadSvg /> }
        <span>{ translate('disc_pdf_report_download') }</span>
      </div>
    );
  };

  const buildPageBlocks = (section = {}, buildingGroupBlocks = false) => {
    const { blocks } = section;

    return blocks?.map((block = {}, blockIndex = 0) => {
      const frame = block.layout?.frame || {};
      let blockComponent = null;

      const segmentsCount = assessment.renderReferenceMin === 0
        ? assessment.renderReferenceMax + 1
        : assessment.renderReferenceMax;

      switch (block.type) {
        case BLOCK_TYPES.PARAGRAPH: {
          blockComponent = (
            <Paragraph
              replacements={{
                username,
                assessment: assessment.title,
              }}
              {...block}
            />
          );
          break;
        }
        case BLOCK_TYPES.GAP_ANALYSIS: {
          blockComponent = (
            <GapAnalysis
              {...block}
              assessmentResults={numberAssessmentResults}
              assessmentResultsAll={numberAssessmentResultsAll}
              segmentsCount={segmentsCount}
              renderMin={assessment.renderReferenceMin}
              forCustomReport
            />
          );
          break;
        }
        case BLOCK_TYPES.PEER_BREAKDOWN: {
          blockComponent = (
            <PeerBreakdown
              {...block}
              blockIndex={blockIndex}
              assessmentResults={numberAssessmentResults}
              assessmentResultsAll={numberAssessmentResultsAll}
              segmentsCount={segmentsCount}
              renderMin={assessment.renderReferenceMin}
              forCustomReport
            />
          );
          break;
        }
        case BLOCK_TYPES.TOP_BOTTOM_X: {
          blockComponent = (
            <TopBottomX
              {...block}
              isPeerAssessment={assessment.peerAssessment}
              assessmentResults={numberAssessmentResults}
              assessmentResultsAll={numberAssessmentResultsAll}
              segmentsCount={segmentsCount}
              renderMin={assessment.renderReferenceMin}
            />
          );
          break;
        }
        case BLOCK_TYPES.SIMPLE_LINE_DIAGRAM: {
          blockComponent = (
            <SimpleLineDiagram
              {...block}
              assessmentResults={numberAssessmentResultsAll}
              segmentsCount={segmentsCount}
              renderMin={assessment.renderReferenceMin}
            />
          );
          break;
        }
        case BLOCK_TYPES.BIPOLAR_SCALE_DIAGRAM: {
          blockComponent = (
            <BipolarScaleDiagram
              {...block}
              assessmentResults={numberAssessmentResultsAll}
            />
          );
          break;
        }
        case BLOCK_TYPES.SPIDER_CHART: {
          blockComponent = (
            <SpiderChart
              {...block}
              isPeerAssessment={assessment.peerAssessment}
              assessmentResults={numberAssessmentResults}
              assessmentResultsAll={numberAssessmentResultsAll}
              segmentsCount={segmentsCount}
            />
          );
          break;
        }
        case BLOCK_TYPES.STRING_RESULTS: {
          blockComponent = (
            <StringResults
              {...block}
              assessmentResults={stringAssessmentResults}
              assessmentResultsAll={stringAssessmentResultsAll}
            />
          );
          break;
        }
        case BLOCK_TYPES.GROUP: {
          // 'group' block type can't contain other group block types
          if (buildingGroupBlocks) {
            break;
          }

          blockComponent = buildPageBlocks(block.content, true);
          break;
        }
        case BLOCK_TYPES.PEER_GROUP_DESCRIPTION: {
          blockComponent = (
            <PeerGroupDescription
              {...block}
              peerGroups={assessment.peerGroups}
            />
          );
          break;
        }
        case BLOCK_TYPES.PERSONAL_NOTES: {
          blockComponent = (
            <PersonalNotes
              notes={notes}
              setNote={setNote}
              {...block}
            />
          );
          break;
        }
        default:
          return null;
      }

      const color = !buildingGroupBlocks && (frame.showBorder || frame.showBackground);
      const frameColor = Config.acceptedFrameColors.includes(frame.color) ? frame.color : 'blue';
      const withBorder = !buildingGroupBlocks && frame.showBorder;
      const withBackground = !buildingGroupBlocks && frame.showBackground;

      return (
        <div
          key={blockIndex}
          style={buildInlineStyle(block.layout)}
          className={classNames(styles.block, {
            [styles[frameColor]]: color,
            [styles.withBorder]: withBorder,
            [styles.withBackground]: withBackground,
          })}
        >
          { blockComponent }
        </div>
      );
    });
  };

  const buildPage = (section = {}, addMainTitle = false) => {
    const { title } = section;

    return (
      <div className={styles.section}>
        { addMainTitle && (
          <div className={styles.mainTitle}>
            { translate(
              userId ? 'peer_assessment_for_title' : reportStructure.title,
              [ '{{assessment}}', assessment?.title, '{{forUser}}', username ],
            ) }
            { ((!isL && !isXl) || reportStructure.sections.length <= 1) && renderDownloadLink(true) }
          </div>
        ) }

        { title && (
          <div className={classNames('bluTypeM', styles.blueTitle)}>
            { translate(title) }
          </div>
        ) }

        { buildPageBlocks(section) }
      </div>
    );
  };

  const buildSubPages = (section = {}) => {
    const subSections = [];

    const defineSubsections = (blocks = []) => {
      blocks.forEach((block = {}, index = 0) => {
        if (block.content?.blocks) {
          defineSubsections(block.content.blocks);
        }

        if (typeof block.content === 'string') {
          const contentMd = block.content.startsWith('[blu-markdown]')
            ? block.content
            : `[blu-markdown] ${block.content}`;
          const content = markdown(contentMd);

          const placeholder = document.createElement('div');
          placeholder.innerHTML = content.props.dangerouslySetInnerHTML.__html;

          const headlineElements = placeholder.querySelectorAll('h1');
          if (headlineElements?.length > 0) {
            headlineElements.forEach((headlineElement) => {
              subSections.push({
                id: headlineElement.id,
                name: headlineElement.innerText,
              });
            });
          }
        }

        if (block.type === 'peer-breakdown'
        && block.configuration?.mode === 'dimension-breakdown'
        && !block.content?.dimension) {
          const breakdownSubSections = numberAssessmentResults?.map(({ id, name }) => ({
            id: `${index}_${id}`,
            name,
          }));
          subSections.push(...breakdownSubSections);
        }
      });
    };

    defineSubsections(section.blocks);
    return subSections;
  };

  const pages = [];
  reportStructure.sections?.forEach((section, index) => {
    const { title } = section;

    pages.push({
      name: translate(title),
      content: () => buildPage(section, index === 0),
      subSections: buildSubPages(section),
    });
  });


  // RENDER: CustomReport
  return (
    <AssessmentReportNext
      titleKey={translate('peer_360_report_header_title', [ '{{assessment}}', assessment?.title ])}
      navigationIsDynamic
      pages={pages}
      onClose={() => onClose?.()}
      menuExtra={renderDownloadLink()}
    />
  );
};

export default CustomReport;
