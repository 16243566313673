// REACT, STYLE, STORIES & COMPONENT
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './AssessmentReportNext.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import {
  Link, CollapsibleMenu, SidePanel, SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint, useFooterOffset } from 'utils/hooks';


// COMPONENT: AssessmentReportNext
const AssessmentReportNext = (props) => {
  // PROPS
  const {
    titleKey = 'title_key',
    pages = [],
    onClose = () => {},
    menuExtra = null,
    navigationIsDynamic = false,
  } = props;

  const [ selectedPage, setSelectedPage ] = useState(0);
  const [ selectedSection, setSelectedSection ] = useState();
  const [ showPanel, setShowPanel ] = useState(false);
  const [ anchorThresholds, setAnchorThresholds ] = useState();
  const mainComponentRef = useRef();
  const anchorRef = useRef();
  const bodyWrapperEl = document.getElementById('body-main')?.parentElement;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const footerOffset = useFooterOffset();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const isLoading = !pages?.length;

  const handleClose = (...args) => {
    setSelectedPage(0);
    onClose(...args);
  };

  // Calculate anchor position on page
  const calcAnchorThreshold = useCallback(() => {
    if (!pages?.length || !bodyWrapperEl) {
      return;
    }

    const anchors = (pages?.[selectedPage]?.subSections ?? []).map(({ id }) => {
      const sectionEl = document.getElementById(id);
      const offset = sectionEl ? sectionEl.offsetTop - bodyWrapperEl.offsetTop : null;
      return { id, offset };
    });

    // Check and fix anchors at the bottom whose title cannot be reached
    // (and thus cannot be highlighted on menu) via scrolling.
    const scrollableOffset = bodyWrapperEl.scrollHeight - bodyWrapperEl.clientHeight;
    const lastValidOffset = [ ...anchors ].reverse().find(({ offset }) => offset < scrollableOffset)?.offset ?? 0;
    anchors.forEach((anchor) => {
      if (anchor.offset > scrollableOffset) {
        const percentage = (anchor.offset - lastValidOffset) / (bodyWrapperEl.scrollHeight - lastValidOffset);
        // eslint-disable-next-line no-param-reassign
        anchor.offset = Math.round(lastValidOffset + ((scrollableOffset - lastValidOffset) * percentage));
      }
    });

    setAnchorThresholds(anchors);
    anchorRef.current = anchors;
  }, [ pages, selectedPage, bodyWrapperEl ]);

  // Reset page scroll to top
  const scrollToTop = useCallback(() => {
    if (bodyWrapperEl) {
      bodyWrapperEl.scrollTo({ top: 0 });
    }
  }, [ bodyWrapperEl ]);

  // Browse to other page or scroll between section
  const browse = ({ index }, sectionId = 'body-main') => {
    setShowPanel(false);
    setSelectedPage(index);
    // Scroll to section or page top. setTimeout in case the page has to be loaded first.
    setTimeout(() => {
      const anchor = anchorRef.current.find(({ id }) => id === sectionId);
      if (!anchor) {
        scrollToTop();
      } else {
        bodyWrapperEl.scrollTo({ top: anchor.offset, behavior: 'smooth' });
      }
    }, 100);
  };


  /**
   * Close panel after widening page.
   */
  const { isL, isXl } = useBreakpoint();
  useEffect(() => {
    if (isL || isXl) {
      setShowPanel(false);
    }
  }, [ isL, isXl ]);

  /**
   * Anchor thresholds have to be recalculated every time the page is resized,
   * plus every time the page changes.
   */
  useEffect(() => {
    // Scroll to section or page top. setTimeout in case the page has to be loaded first.
    setTimeout(() => { calcAnchorThreshold(); }, 1);

    const mutationObserver = new MutationObserver(calcAnchorThreshold);
    const resizeObserver = new ResizeObserver(calcAnchorThreshold);
    mutationObserver.observe(mainComponentRef.current, { childList: true, subtree: true });
    resizeObserver.observe(mainComponentRef.current);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [ calcAnchorThreshold ]);

  /**
   * Update selectedSection on scroll.
   */
  useEffect(() => {
    if (!bodyWrapperEl) {
      return undefined;
    }

    const handleScroll = () => {
      if (!anchorThresholds) {
        return;
      }
      const current = [ ...anchorThresholds ].reverse().find(({ offset }) => bodyWrapperEl.scrollTop >= offset);
      setSelectedSection(current?.id);
    };

    bodyWrapperEl.addEventListener('scroll', handleScroll);
    return () => bodyWrapperEl.removeEventListener('scroll', handleScroll);
  }, [ anchorThresholds, bodyWrapperEl ]);


  // Conditions in comments: https://blueexcellence.atlassian.net/browse/BQP-1018?focusedCommentId=22441
  const noSideNavigation = navigationIsDynamic && !(pages.length > 1 || pages[0]?.subSections?.length > 1);
  const noFooterNavigation = navigationIsDynamic && pages.length === 1;

  // RENDER: Navigation Menu
  const renderNavMenu = (fullyExpanded = false) => {
    const navigationMenu = pages?.map((page = {}) => {
      // Create sublist
      let list;
      if (page.subSections) {
        list = page.subSections.map(({ name, id }) => ({
          id,
          name,
          onClick(event, ancestor) {
            browse(ancestor, id);
          },
        }));
      }

      return {
        id: page.id,
        name: page.name,
        onClick() {
          browse(this);
        },
        list,
      };
    });

    if (isLoading) {
      return <SkeletonFlexible repeat={6} />;
    }

    return (
      <>
        <h6>
          { translate('table_of_contents') }
        </h6>
        <div className={styles.collapsibleMenuWrapper}>
          <CollapsibleMenu
            content={navigationMenu}
            selectedNodeIndex={selectedPage}
            selectedSection={selectedSection}
            fullyExpanded={fullyExpanded}
          />
        </div>
        { menuExtra }
      </>
    );
  };

  // RENDER: AssessmentReportNext
  return createPortal(
    <div className={styles.assessmentReportNext}>
      { /* HEADER */ }
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.titleSection}>
            { !noSideNavigation && (
              <div
                role='presentation'
                className={classNames(styles.icon, styles.menu)}
                onClick={() => setShowPanel(true)}
              >
                <IconsSvg.BurgerPartial />
              </div>
            ) }

            <div className={styles.title}>
              { translate(titleKey) }
            </div>
          </div>

          <div className={styles.closeSection}>
            <div
              role='presentation'
              className={styles.icon}
              onClick={handleClose}
            >
              <IconsSvg.CloseBig />
            </div>
          </div>
        </div>
      </div>

      { /* BODY */ }
      <div className={classNames(styles.bodyWrapper, {
        [styles.noSideNavigation]: noSideNavigation,
        [styles.noFooterNavigation]: noFooterNavigation,
      })}
      >
        <div className={styles.body} id='body-main'>
          { !noSideNavigation && (
            <aside>
              <div className={styles.asideContent}>
                { renderNavMenu() }
              </div>
            </aside>
          ) }
          <main ref={mainComponentRef}>
            { !isLoading
              ? pages[selectedPage].content(props)
              : <SkeletonFlexible repeat={9} /> }
          </main>
        </div>
      </div>

      { /* FOOTER */ }
      { !noFooterNavigation && (
        <footer className={styles.footerWrapper} style={{ top: footerOffset }}>
          <div className={styles.footer}>
            <aside />
            <main>
              { selectedPage
                ? (
                  <Link
                    onClick={() => {
                      setSelectedPage((state) => state - 1);
                      scrollToTop();
                    }}
                  >
                    <div className={styles.pageArrow}>
                      <IconsSvg.ArrowLeft />
                    </div>
                    <div className={styles.pageTitle}>
                      { pages[selectedPage - 1]?.name }
                    </div>
                  </Link>
                ) : <div /> }

              { (!isLoading && selectedPage !== pages.length - 1)
                ? (
                  <Link
                    onClick={() => {
                      setSelectedPage((state) => state + 1);
                      scrollToTop();
                    }}
                  >
                    <div className={classNames(styles.pageTitle, styles.right)}>
                      { pages[selectedPage + 1]?.name }
                    </div>
                    <div className={styles.pageArrow}>
                      <IconsSvg.ArrowRight />
                    </div>
                  </Link>
                ) : <div /> }
            </main>
          </div>
        </footer>
      ) }

      { /* DRAWER */ }
      { showPanel && (
        <SidePanel
          onClose={() => setShowPanel(false)}
          left
          showArrow
        >
          <div className={classNames(
            styles.sidePanel,
            { [styles.loading]: isLoading },
          )}
          >
            { renderNavMenu(true) }
          </div>
        </SidePanel>
      ) }
    </div>,
    document.body,
  );
};

export default AssessmentReportNext;
