// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './BipolarScaleDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { BipolarDiagram } from 'ui/basic';
import { setDecimalSeparator } from 'utils/userpreference';
import { useTranslate } from 'utils/translator';


// COMPONENT: BipolarScaleDiagram
const BipolarScaleDiagram = (props) => {
  // PROPS
  const {
    configuration = {},
    content = {},
    assessmentResults,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const {
    dimension: dimensionId, leftLabel, rightLabel,
  } = content;

  const flatAssessmentResults = [
    ...assessmentResults,
    ...assessmentResults.map(({ subDimensions = [] }) => subDimensions),
  ].flat();

  const dimension = flatAssessmentResults.find(({ id }) => id === dimensionId) || {};
  const score = dimension.result || 0;

  const { minResult = 0, maxResult = 0 } = dimension;
  const resultRange = minResult || maxResult ? [ minResult, maxResult ] : null;
  const { from = 1, to = 10 } = dimension.range || {};
  const centerPoint = configuration.centerPoint || 0;

  // RENDER: BipolarScaleDiagram
  return (
    <div className={styles.bipolarScaleDiagram}>
      <div className={styles.dimensionWrapper}>
        { resultRange ? (
          <div className={styles.dimensionRange}>
            { translate('mind_journey_result__range_lbl') }
            { ': ' }
            <span>
              { `${setDecimalSeparator(minResult)} - ${setDecimalSeparator(maxResult)}` }
            </span>
          </div>
        ) : (
          <div />
        ) }
        <div className={classNames(styles.dimension, { [styles.bold]: resultRange })}>
          { dimension.name }
        </div>
        <div className={styles.dimensionScore}>
          { setDecimalSeparator(score) }
        </div>
      </div>
      <BipolarDiagram
        diagramRange={[ from, to ]}
        score={score}
        range={resultRange}
        markers={[]}
        center={Math.max(Math.min(to, centerPoint), from)}
      />
      <div className={styles.labels}>
        <div className={score < centerPoint ? styles.blue : null}>
          { leftLabel }
        </div>
        <div className={score > centerPoint ? styles.blue : null}>
          { rightLabel }
        </div>
      </div>
    </div>
  );
};

export default BipolarScaleDiagram;
