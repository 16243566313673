import React, { lazy } from 'react';

const AdminMain = lazy(() => import('./pages/AdminMain' /* webpackChunkName: "admin" */));

export const routesConfigAdmin = {
  show: {
    forFlavor: 'adminFrontend',
    forUserGroup: 'bluquistAdmin',
  },
  routes: [
    {
      path: '/login',
      pageType: 'standalone',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'AdminLogin',
      show: {
        needsLogout: true,
      },
      tools: {
        browserTitleKey: 'login_button_login',
      },
    },
    {
      path: '/',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'AdminOverview',
      inMainNav: {
        labelKey: 'main_navigation_item_1',
      },
    },
    {
      path: '/admin/instances',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'Instances',
      inMainNav: {
        labelKey: 'main_navigation_item_10',
      },
      show: {
        forFlavor: 'adminFrontend',
        forUserGroup: 'bluquistAdmin',
      },
      subRoutes: [
        {
          path: '/admin/instances/:instanceId/details',
          PageComponent: () => <AdminMain />,
          LazyPageComponent: 'InstanceDetails',
          show: {
            forFlavor: 'adminFrontend',
            forUserGroup: 'bluquistAdmin',
          },
          subRoutes: [
            {
              path: '/admin/instances/:instanceId/details',
              inSubNav: {
                labelKey: 'instance_subnav_insights',
              },
            },
            {
              path: '/admin/instances/:instanceId/configuration',
              PageComponent: () => <AdminMain />,
              LazyPageComponent: 'InstanceConfiguration',
              inSubNav: {
                labelKey: 'instance_subnav_configuration',
              },
            },
            {
              path: '/admin/instances/:instanceId/settings',
              PageComponent: () => <AdminMain />,
              LazyPageComponent: 'InstanceSettings',
              inSubNav: {
                labelKey: 'main_navigation_item_8',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/admin/new-instance',
      pageType: 'modal',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'NewInstance',
    },
    {
      path: '/admin/users',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'Users',
      show: {
        forFlavor: 'adminFrontend',
        forUserGroup: 'bluquistAdmin',
      },
      inMainNav: {
        labelKey: 'main_navigation_item_11',
      },
      subRoutes: [
        {
          path: '/admin/users/:userId',
          PageComponent: () => <AdminMain />,
          LazyPageComponent: 'UserDetails',
        },
      ],
    },
    {
      path: '/admin/product-settings',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'ProductSettings',
      inMainNav: {
        labelKey: 'product_settings_title',
      },
    },
    {
      path: '/admin/settings',
      PageComponent: () => <AdminMain />,
      LazyPageComponent: 'AdminSettings',
      inMainNav: {
        labelKey: 'main_navigation_item_8',
      },
    },
  ],

};
