// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './AssessmentCarousel.module.scss';

// 3RD PARTY
import { useHistory, useParams } from 'react-router-dom';

// OTHER COMPONENTS
import { AssessmentTeaserNext, Modal } from 'ui/basic';
import CustomScrollable from './scroller/CustomScrollable';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_RECURRENCE_TYPES, ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { getFullName } from 'utils/users';

import { hasStoredAnswers } from 'ui/molecules/AssessmentNext/AssessmentNext.logic';
import { ASSESSMENT_NEXT_TYPES } from 'ui/molecules/AssessmentNext/AssessmentNext.config';

// STORE NEXT
import { useSelector } from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import {
  selectFeaturesNext,
  selectInstanceContainsBalancedYouFeatures,
  selectCompany,
  selectIsPeerControlled,
  selectIsPeerController,
} from 'store/selectors/configuration';


// COMPONENT: AssessmentCarousel
const AssessmentCarousel = ({
  scrollableRef,
  onPaginationAvailabilities,
  assessments,
  surveyParticipations,
  teaserProps = {},
  setTeasersLengthCb,
  setRmpImportModalVisible,
  showPaginationBubbles = true,
  children,
  includeGarminTeaser = true,
}) => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const history = useHistory();
  const params = useParams();

  // STATE
  const [ teasers, setTeasers ] = useState([]);
  const [ garminConnectModalVisible, setGarminConnectModalVisible ] = useState();

  const [ garminLearnMoreModalVisible, setGarminLearnMoreModalVisible ] = useState();
  const [ garminLearnMoreModalScreen, setGarminLearnMoreModalScreen ] = useState(1);
  const [ garminLearnMoreModalTitle, setGarminLearnMoreModalTitle ] = useState();
  const [ garminLearnMoreModalCopy, setGarminLearnMoreModalCopy ] = useState();
  const [ garminConnectProcessing, setGarminConnectProcessing ] = useState();

  // SELECTORS
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const featuresNext = useSelector(selectFeaturesNext);
  const garminAccountConnected = useSelector(fromGarminSelectors.selectGarminConnectionStatus);
  const containsBalancedYouFeatures = useSelector(selectInstanceContainsBalancedYouFeatures);
  const company = useSelector(selectCompany);
  const isPeerControlled = useSelector(selectIsPeerControlled);
  const isPeerController = useSelector(selectIsPeerController);

  const showGarminTeaser = featuresNext.garmin && garminAccountConnected === null && includeGarminTeaser;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const handleGarminConnect = () => {
    setGarminConnectProcessing(true);
    api.post(`core/integrations/garmin/connect?redirect=${window.location.origin}/my-profile`)
    .then(({ status, headers }) => {
      if (status === 303) {
        const location = headers['x-location'];
        if (location) {
          window.location.href = location;
        }
      }
      setGarminConnectProcessing(false);
    })
    .catch((error) => {
      console.error(error.message);
      setGarminConnectProcessing(false);
    });
  };

  // Hook to create teasers
  useEffect(() => {
    if (!assessments?.length || showGarminTeaser) {
      return;
    }

    /**
     * Prepare progress on peer assessments
     */
    const getPeerProgress = (assessment) => {
      const { peerProgress, peerGroups, asPeer } = assessment;

      if (peerProgress) {
        Object.keys(peerProgress).forEach((key) => {
          peerProgress[key].label = key === 'self'
            ? translate('peer_360_peer_perspectives_self')
            : peerGroups[key]?.label;
        });
      }

      return asPeer ? undefined : peerProgress;
    };

    /**
     * Get description for assessment
     */
    const getDescription = (assessment) => {
      if (assessment.id === 'garmin') {
        return translate('garmin_connect_copy');
      }

      // if it is a time for re-taking the balanced you assessment ->
      // the description should be adjusted accordingly
      if (assessment.id === ASSESSMENT_TYPES.BALANCED_YOU && assessment.completed && !assessment.locked) {
        return translate('by_assessment_retake_copy');
      }

      const { asPeer } = assessment;
      if (asPeer) {
        return translate('peer_assessment_for_descr', [
          '{{peerGroup}}', asPeer.peerGroup.name,
          '{{forUser}}', getFullName(asPeer.forUser),
          '{{assessment}}', assessment.title,
        ]);
      }

      return assessment.info_texts?.short_description;
    };

    /**
     * Get title for assessment
     */
    const getTitle = (assessment) => {
      const { title, asPeer } = assessment;

      if (asPeer) {
        return translate('peer_assessment_for_title', [
          '{{assessment}}', title,
          '{{forUser}}', getFullName(asPeer.forUser),
        ]);
      }

      return title;
    };

    /**
     * Tell whether the assessment should be treated as "started" (0 < answers < ALL).
     */
    const isAssessmentStarted = (assessment) => {
      // Check whether there are answers in the store (and it's still in grace period)
      const assessmentId = ASSESSMENT_NEXT_TYPES[assessment.id] || assessment.id;
      if (hasStoredAnswers(assessmentId, me.id)) {
        return true;
      }

      // When multi_session is not enabled, restart from scratch
      if (!assessment.multi_session) {
        return false;
      }

      // Case: peer assessment
      if (assessment?.peerProgress) {
        return true;
      }

      // Count stage answers
      const stages = assessment.stages ?? [];
      const totalQuestions = stages.reduce((acc, el) => acc + el.length, 0);
      const totalAnswers = stages.reduce((acc, el) => acc + el.questionsAnswered, 0);

      return (totalAnswers > 0 && totalAnswers < totalQuestions)
        || (assessment.observerMode && !assessment.completed && totalAnswers > 0);
    };

    /**
     * Calculate properties for 1st teaser button.
     */
    const getPrimaryButton = (assessment) => {
      // Case survey
      if (assessment.survey) {
        return {
          label: assessment.progress
            ? 'task_list_continue_survey_btn'
            : 'task_list_start_survey_btn',
          onClick: () => history.push(`/surveys/${assessment.survey.id}/answer`),
        };
      }

      // Case GARMIN
      if (assessment.id === 'garmin') {
        return {
          label: 'connect_lbl',
          onClick: () => setGarminConnectModalVisible(true),
        };
      }

      // Case PEER assessment
      if (assessment.peerAssessment && !assessment.asPeer) {
        return {
          label: assessment.peerProgress ? 'manage_lbl' : 'assessment_reminder_md_start_btn',
          onClick: () => {
            let target = `/assessments/${assessment.id}/peer/settings`;
            if (isPeerController && params.employeeId) {
              target += `?user=${params.employeeId}`;
            }
            history.push(target);
          },
        };
      }

      return {
        label: isAssessmentStarted(assessment)
          ? 'resume_assessment_lbl'
          : 'assessment_reminder_md_start_btn',
        onClick: () => history.push(`/assessments/${assessment.id}`),
      };
    };

    /**
     * Calculate properties for 2nd teaser button.
     */
    const getSecondaryButton = (assessment) => {
      // Case GARMIN
      if (assessment.id === 'garmin') {
        return {
          label: 'big5_results_button_learnmore_dim1',
          onClick: () => {
            setGarminLearnMoreModalTitle(translate('garmin_connect'));
            setGarminLearnMoreModalCopy(translate('garmin_connect_learn_more_copy'));
            setGarminLearnMoreModalVisible(true);
          },
        };
      }

      // Case PEER assessment
      if (assessment.peerProgress && !assessment.asPeer) {
        const selfProgress = assessment.peerProgress?.self?.progress;
        const selfCompletedAt = assessment.peerProgress?.self?.completedAt;
        if ((selfProgress === 100 && selfCompletedAt)
          || (isPeerController && params.employeeId) || assessment.peerOnly) {
          return null; // hide button
        }
        return {
          label: selfProgress ? 'resume_assessment_lbl' : 'peer_360_teaser_button_start',
          onClick: () => history.push(`/assessments/${assessment.id}`),
        };
      }

      // Default
      return {
        label: 'big5_results_button_learnmore_dim1',
        onClick: null, // if missing, modal will be open inside
      };
    };

    /**
     * Calculate properties for 3rd teaser button.
     */
    const getTertiaryButton = (assessment) => {
      // Case RMP
      if (assessment.id === ASSESSMENT_TYPES.RMP && company.allowAssessmentImport_rmp) {
        return {
          label: 'profile_import_lbl',
          onClick: () => setRmpImportModalVisible(true),
        };
      }

      // Default
      return null;
    };

    /* Create teasers from assessments */
    let newTeasers = [ ...assessments ];

    // add Garmin Connect teaser at the beginning
    if (containsBalancedYouFeatures && showGarminTeaser) {
      newTeasers.unshift({ id: 'garmin', title: translate('garmin_connect') });
    }

    newTeasers = newTeasers
    // hide non-available assessments and locked ones
    .filter((el) => el.userAvailable !== false && !el.locked)
    // hide completed recurring assessments that should not be advertised again
    .filter((el) => !(el.recurrenceType && el.recurrenceType !== ASSESSMENT_RECURRENCE_TYPES.NONE
      && el.advertiseRecurrence === false
      && el.completed))
    // hide completed non-recurring assessments
    .filter((el) => !((el.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.NONE || !el.recurrenceType) && el.completed))
    // if peer controlled, hide non-started peer assessments
    .filter((el) => !(isPeerControlled && el.peerAssessment && !el.peerProgress))
    .map((el) => ({
      assessmentType: el.id,
      scope: el.info_texts?.required_time,
      details: el.info_texts?.long_description,
      peerAssessment: el.peerAssessment,
      showLoopIcon: el.peerAssessment && !el.asPeer,
      title: getTitle(el),
      peerProgress: getPeerProgress(el),
      description: getDescription(el),
      primaryButton: getPrimaryButton(el),
      secondaryButton: getSecondaryButton(el),
      tertiaryButton: getTertiaryButton(el),
      started: isAssessmentStarted(el),
    }))
    .sort((a, b) => b.started - a.started); // place started assessments at the beginning

    // Add survey invites
    if (surveyParticipations?.length) {
      const newSurveyTeasers = surveyParticipations.map((el) => ({
        isSurvey: true,
        assessmentType: el.id,
        title: el.survey.name,
        description: el.survey.description,
        scope: translate('survey_time_scope', [ '{{minutes}}', el.survey.length + 1 ]),
        primaryButton: getPrimaryButton(el),
      }));
      newTeasers = [ ...newSurveyTeasers, ...newTeasers ];
    }

    setTeasers(newTeasers);
    setTeasersLengthCb?.(newTeasers.length);
  }, [
    me.id,
    assessments,
    surveyParticipations,
    containsBalancedYouFeatures,
    showGarminTeaser,
    translate,
    company.allowAssessmentImport_rmp,
    history,
    setTeasersLengthCb,
    setRmpImportModalVisible,
    isPeerControlled,
    isPeerController,
    params,
  ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: AssessmentCarousel
  if (!teasers.length && !children) {
    return null;
  }

  return (
    <div className={styles.assessmentCarousel}>
      <CustomScrollable
        ref={scrollableRef}
        showPaginationBubbles={showPaginationBubbles}
        onPaginationAvailabilities={onPaginationAvailabilities}
      >
        { [ ...teasers.map((teaser) => (
          <div
            className={styles.teaser}
            key={teaser.assessmentType}
          >
            <AssessmentTeaserNext
              {...teaser}
              {...teaserProps}
            />
          </div>
        )), children ].filter(Boolean) }
      </CustomScrollable>

      { /* FLOATERS */ }

      { /* GARMIN CONNECT MODAL */ }
      { garminConnectModalVisible && (
        <Modal
          header={translate('garmin_connect_modal_long_title')}
          secondaryButtonTitle={translate('cancel_lbl')}
          primaryButtonTitle={translate('continue_lbl')}
          primaryButtonDisabled={garminConnectProcessing}
          closeOnConfirm={false}
          onClose={() => setGarminConnectModalVisible(false)}
          onConfirm={() => handleGarminConnect()}
        >
          { translate('garmin_connect_modal_long_copy') }
        </Modal>
      ) }

      { /* GARMIN CONNECT LEARN MORE MODAL */ }
      { garminLearnMoreModalVisible && (
        <Modal
          header={garminLearnMoreModalTitle}
          secondaryButtonTitle={translate('close_lbl')}
          primaryButtonTitle={translate('continue_lbl')}
          primaryButtonDisabled={garminConnectProcessing}
          closeOnConfirm={false}
          onClose={() => {
            setGarminLearnMoreModalScreen(1);
            setGarminLearnMoreModalVisible(false);
          }}
          onConfirm={() => {
            if (garminLearnMoreModalScreen === 2) {
              handleGarminConnect();
            } else {
              setGarminLearnMoreModalTitle(translate('garmin_connect_modal_long_title'));
              setGarminLearnMoreModalCopy(translate('garmin_connect_modal_long_copy'));
              setGarminLearnMoreModalScreen(2);
            }
          }}
        >
          { garminLearnMoreModalCopy }
        </Modal>
      ) }

    </div>
  );
};

export default AssessmentCarousel;
